import React, { useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import { actions, connect } from "client-page";
import { DB_COLORS } from "../../theme";
import styled from "styled-components";
import api from "../../server/rest/api";
import heic2any from "heic2any";
import Tiff from "tiff.js";

function EdmsImageUploadModal({ upload_url, data, closeModal, modalId }) {
  const [uploadFiles, setUploadFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  function validateFile(file) {
    if (!file) {
      return false;
    }

    // png,jpg,jpeg
    if (
      !(
        (file.name.length > 4 &&
          file.name.substr(file.name.length - 4) &&
          file.name.substr(file.name.length - 4).toLowerCase() === ".png") ||
        (file.name.length > 4 &&
          file.name.substr(file.name.length - 4) &&
          file.name.substr(file.name.length - 4).toLowerCase() === ".jpg") ||
        (file.name.length > 5 &&
          file.name.substr(file.name.length - 5) &&
          file.name.substr(file.name.length - 5).toLowerCase() === ".jpeg") ||
        (file.name.length > 4 &&
          file.name.substr(file.name.length - 4) &&
          file.name.substr(file.name.length - 4).toLowerCase() === ".gif") ||
        (file.name.length > 4 &&
          file.name.substr(file.name.length - 4) &&
          file.name.substr(file.name.length - 4).toLowerCase() === ".tif") ||
        (file.name.length > 5 &&
          file.name.substr(file.name.length - 5) &&
          file.name.substr(file.name.length - 5).toLowerCase() === ".tiff") ||
        (file.name.length > 4 &&
          file.name.substr(file.name.length - 4) &&
          file.name.substr(file.name.length - 4).toLowerCase() === ".bmp") ||
        (file.name.length > 5 &&
          file.name.substr(file.name.length - 5) &&
          file.name.substr(file.name.length - 5).toLowerCase() === ".heic")
      )
    ) {
      alert("이미지 파일을 업로드 하여 주십시오.");
      return false;
    } else if (file.size > 12 * 1024 * 1024) {
      alert("12MB 보다 작은 파일만 업로드할 수 있습니다.");
      return false;
    }
    return true;
  }

  /**
   * validateFile 함수를 통해 유효한 파일만을 Drop 할 수 있음
   *
   * @param e
   */
  function handleDropFile(e) {
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (!validateFile(e.dataTransfer.files[0])) {
        e.preventDefault();
        e.dataTransfer.clearData();
      }
    }
  }

  async function handleChange(e) {
    const { files } = e.target;
    if (files.length === 0) return;

    let newUploadFiles = [];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!validateFile(file)) {
        return false;
      }
      try {
        if (
          (file.name.length > 4 &&
            file.name.substr(file.name.length - 4) &&
            file.name.substr(file.name.length - 4).toLowerCase() === ".tif") ||
          (file.name.length > 5 &&
            file.name.substr(file.name.length - 5) &&
            file.name.substr(file.name.length - 5).toLowerCase() === ".tiff")
        ) {
          const arrayBuffer = await readFileAsArrayBuffer(file);
          const tiffImage = new Tiff({ buffer: arrayBuffer });

          for (let page = 0; page < tiffImage.countDirectory(); page++) {
            tiffImage.setDirectory(page);
            const tiffCanvas = tiffImage.toCanvas();

            // 변환된 JPG 이미지를 Data URL로 얻기
            const fileDataUrl = tiffCanvas.toDataURL("image/jpeg");
            const fileName = file.name.replace(/\.[^.]+$/, `${page}.jpg`);
            let uploadFile = {
              fileName,
              fileRaw: fileDataUrl.split(",")[1],
              imageDataURL: fileDataUrl,
            };
            newUploadFiles.push(uploadFile);
          }
        } else if (
          file.name.length > 5 &&
          file.name.substr(file.name.length - 5) &&
          file.name.substr(file.name.length - 5).toLowerCase() === ".heic"
        ) {
          const jpgFile = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.5, // cuts the quality and size by half
          });
          const fileDataUrl = await readFile(jpgFile);
          const fileName = file.name.replace(/\.[^.]+$/, ".jpg");
          let uploadFile = {
            fileName,
            fileRaw: fileDataUrl.split(",")[1],
            imageDataURL: fileDataUrl,
          };
          newUploadFiles.push(uploadFile);
        } else {
          const fileDataUrl = await readFile(file);

          let uploadFile = {
            fileName: file.name,
            fileRaw: fileDataUrl.split(",")[1],
            imageDataURL: fileDataUrl,
          };
          newUploadFiles.push(uploadFile);
        }
      } catch (e) {
        alert(e.message);
      }
    }

    setUploadFiles((prev) => {
      let next = [...prev, ...newUploadFiles];
      if (next.length > 8) {
        alert("한번에 8개 파일만 업로드 가능합니다.");
        next.splice(8);
      }
      return next;
    });
  }

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setIsUploading(true);
      const result = await fileUpload(uploadFiles);
      setIsUploading(false);

      if (result && result.status) {
        if (result.status.code === 0) {
          alert("업로드가 완료되었습니다.");
        } else {
          throw new Error(result.status.detail);
        }
      } else {
        throw new Error("오류가 발생하였습니다.");
      }
    } catch (e) {
      alert(e.message);
      console.error(e);
    }
    closeModal(modalId);
  }

  function fileUpload(uploadFiles) {
    return api.edmsImageUpload({
      ...data,
      files: uploadFiles.map((file) => ({
        file_name: file.fileName,
        file_raw: file.fileRaw,
      })),
    });
  }

  return (
    <FloatingContainer>
      <Container>
        <Header>이미지 업로드</Header>
        <Description>
          {"12MB 이하의 이미지 파일 8개 만 업로드 가능합니다."}
          <br />
          {"(tif, tiff, jpg, jpeg, gif, png, bmp, heic 파일)"}
          <br />
        </Description>
        <FileInputContainer>
          <input
            type="file"
            accept="image/gif, image/jpeg, image/png, image/tiff, image/bmp, image/heic"
            multiple
            onChange={handleChange}
            onDrop={handleDropFile}
            disabled={isUploading}
          />
        </FileInputContainer>
        <Gallery>
          {uploadFiles.map((uploadFile) => (
            <img src={uploadFile.imageDataURL} alt={uploadFile.fileName} />
          ))}
        </Gallery>
        <Bottom>
          {uploadFiles.length > 0 && `총 ${uploadFiles.length}개 이미지  `}
          <SubmitButton
            type="submit"
            onClick={handleSubmit}
            disabled={uploadFiles.length === 0}
          >
            {isUploading ? "업로드 중..." : "업로드"}
          </SubmitButton>
          <Button
            onClick={() => {
              closeModal(modalId);
            }}
          >
            취소
          </Button>
        </Bottom>
      </Container>
    </FloatingContainer>
  );
}

// const toBase64 = async (file) =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });

function readFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve(reader.result);
    });

    reader.addEventListener("error", () => {
      reject(new Error("Failed to read the file."));
    });

    reader.readAsDataURL(file);
  });
}
function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve(reader.result);
    });

    reader.addEventListener("error", () => {
      reject(new Error("Failed to read the file."));
    });

    reader.readAsArrayBuffer(file);
  });
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  & {
    position: relative;
    top: 50%;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transform: translateY(-50%);
    padding: 15px;
    margin: 10px;
    border-radius: 20px;
  }

  & > * {
    margin-bottom: 15px;
  }
`;
const Header = styled.div`
  font-size: 18px;
`;
const Description = styled.div`
  font-size: 13px;
`;
const RatingList = styled.div`
  text-align: center;
`;
const Rating = styled.div`
  & {
    display: inline-block;
    width: 50px;
    height: 50px;=
  }
`;
const Bottom = styled.div`
  text-align: center;
`;
const SubmitButton = styled.button`
  background-color: ${DB_COLORS.DB_CHATBOT_GREEN};
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin:4px;
  cursor:pointer;
  color: ${DB_COLORS.DB_WHITE};
`;
const Button = styled.button`
  background-color: ${DB_COLORS.DB_DARK_GRAY};
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin:4px;
  cursor:pointer;
  color: ${DB_COLORS.DB_WHITE};
`;

const FileInputContainer = styled.div`
  display: flex;

  & > input {
    flex: 1;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    font-size: 15px;
    margin-right: 10px;
    color: transparent;
  }

  & > .file-input-button-container {
    margin: auto;

    & > button {
      margin: auto;
    }
  }
`;

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 120px;
  max-height: 300px;
  overflow-y: auto;
  justify-content: flex-start;
  margin: 0px 10px;
  & > img {
    width: 120px;
    height: 120px;
    margin: 10px;
    object-fit: cover;
    border-radius:4px;
  }
`;

const mapStateToProps = (state) => ({
  modeType: state.system.mode.type,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      minimizeWindow: actions.minimizeWindow,
      closeModal: actions.closeModal,
      sendMessage: actions.sendMessage,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EdmsImageUploadModal);
