import api from "../rest/api";
import queryString from "query-string";
import { toMessage } from "../transform";
import { getStore, utils, constants, sender } from "client-page";
import { AppModeTypes } from "../../shared/modes";
import actions from "../../redux/actions";
import { CounselState } from "../../redux/reducers/counselor";

const TAG = "socket.io/event";
// debugger;
// const {
//   setConnection,
//   receiveChatbotMessage,
//   loadCustomerMessages,
//   addMessage,
//   changeAppMode,
//   senderWriting,
// } = actions;
const {} = utils;
const { RoleType } = constants;

// function onPostMessage(event) {
//   logger.verbose(TAG, "onPostMessage()", event);

//   if (event === null) {
//     return;
//   }

//   let eventData =
//     typeof event.data === "object" ? event.data : JSON.parse(event.data);
//   logger.verbose(TAG, eventData);

//   let { type } = eventData;

//   // type 에 따라 처리
// }
const KP_COUNSEL_DELAY = 20000;
const kpDelayModalId = "KP_COUNSEL_MODAL";
let kpDelayTimeoutId = undefined;

export default {
  onconnect() {
    const store = getStore();
    store.dispatch(actions.setConnection(true));

    // Mobile web 에서의 groobee scenario 처리
    // scenario_id 가 존재하는 경우 counselGreeting() 요청을 하지 않고 바로 return
    // const query = queryString.parse(window.location.search);
    // if (
    //   query &&
    //   Object.keys(query).length !== 0 &&
    //   query.type &&
    //   query.scenario_id
    // ) {
    //   api.customerPostbackScenario(query.scenario_id);
    //   return;
    // }

    // Socket 연결에 성공하면 counselGreeting API 를 요청함(인사말)
    // api.counselGreeting().then((result) => {
    //   //store.dispatch(saveBanner(result));
    // });

    // 현재 세션의 과거 메시지를 가져온다.
    //store.dispatch(actions.loadCustomerMessages(false));
  },

  ondisconnect() {
    const store = getStore();
    store.dispatch(actions.setConnection(false));
  },

  onerror() {
    const store = getStore();
    store.dispatch(actions.setConnection(false));
  },
  onmessage(onMessageData) {
    // TODO: onMessageData 에 정상적인 key 값이 존재하는지 판별하는 로직 추가
    const store = getStore();
    const state = store.getState();
    const channel_code = state.system.channelId;
    if (
      channel_code &&
      onMessageData.channel_code &&
      channel_code !== onMessageData.channel_code
    )
      return null;
    const kp = channel_code ? channel_code.indexOf("KP") >= 0 : false;

    let { data, delay } = onMessageData;

    switch (onMessageData.type) {
      case "messages":
        // Websocket 을 통해 서버로부터 message 를 받아오는 event

        /**
         * AnyMessage queue 의 dequeue 를 통해 parameter 로 들어온 데이터를 처리하는 함수
         * 1. Sender type 이 상담사인 경우
         *     addMessage action 을 호출하여 즉시 메시지를 추가한다.
         * 2. Sender type 이 챗봇인 경우
         *     writingMessage action 을 호출하여 Component 에서는 Loading 말풍선을 보여주고
         *     데이터의 delay * ratio 만큼의 지연시간 후에 replaceMessage action 을 호출하여 말풍선에 메시지을 추가한다.
         *     (* ratio(0 ~ 1): 고객사의 종류에 따라 지연시간을 달리한다. 값이 클 수록 지연시간은 길어진다.)
         *
         * @param {*} _item
         */

        // data 는 다수의 item 으로 구성되어 있을 수 있기 때문에
        // messageQueue 에 enqueue 작업을 수행한다
        //debugger;
        data.forEach((_item) => {
          const message = toMessage(_item);
          if (message) {
            if (message.senderId === sender.SenderID.CHATBOT) {
              store.dispatch(actions.receiveChatbotMessage(message, delay));
            } else {
              store.dispatch(actions.addMessage(message));
            }
          }
          //messageQueue.enqueue(_item, _delay, callback);
        });
        break;
      case "wait_status":
        store.dispatch(
          actions.changeAppMode({
            type: AppModeTypes.COUNSEL_WAIT,
            props: {
              resetTimeout: true,
              timeout: onMessageData.data.timeout,
              timeoutMessage: onMessageData.data.timeout_title,
            },
          })
        );
        break;
      case "wait_excuse_message":
        {
          const { excuse_messages } = onMessageData.data;
          if (excuse_messages)
            excuse_messages.forEach((message) => {
              const { setting, millis } = message;
              if (setting && millis)
                setTimeout(() => {
                  try {
                    api.customerCounselExcuseMessage({ setting });
                  } catch (e) {
                    console.log(e);
                  }
                }, millis);
            });
        }
        break;

      case "counsel":
        if (onMessageData.data.state === CounselState.Waiting) {
          store.dispatch(actions.disableAllMessages());
          store.dispatch(
            actions.changeAppMode({
              type: AppModeTypes.COUNSEL,
              props: {
                counselId: onMessageData.data.id,
              },
            })
          );
          if (kp && !kpDelayTimeoutId) {
            kpDelayTimeoutId = setTimeout(() => {
              kpDelayTimeoutId = undefined;
              store.dispatch(
                actions.pushModal({
                  modalId: kpDelayModalId,
                  modalType: "kpCounselDelay",
                  modalProps: {},
                })
              );
            }, KP_COUNSEL_DELAY);
          }
        } else if (onMessageData.data.state === CounselState.Counseling) {
          if (kp) {
            if (kpDelayTimeoutId) clearTimeout(kpDelayTimeoutId);
            store.dispatch(actions.closeModal(kpDelayModalId));
            kpDelayTimeoutId = undefined;
          }
        } else if (onMessageData.data.state === CounselState.Finish) {
          const counselId = onMessageData.data.id;
          store.dispatch(
            actions.changeAppMode({
              type: AppModeTypes.DEFAULT,
            })
          );
          if (!kp && state.counselor.counselId === counselId) {
            store.dispatch(actions.disableAllMessages());
            store.dispatch(
              actions.pushModal({
                modalType: "rating",
                modalProps: {},
              })
            );
          }
        }
        break;

      // case "input_status":
      //   if (data.type === "START") {
      //     store.dispatch(actions.senderWriting(true));
      //   } else if (data.type === "END") {
      //     store.dispatch(actions.senderWriting(false));
      //   }
      //   break;

      case "counselor_input_start":
        store.dispatch(actions.senderWriting(true));
        break;

      case "counselor_input_end":
        store.dispatch(actions.senderWriting(false));
        break;
      case "message_read":
        store.dispatch(actions.setLastReadMessage(onMessageData.data.id));
        break;

      case "call_transfer":
        if (onMessageData.data?.data) {
          const {
            customer_session: { customer_session_id },
            site_code,
            api_path,
            api_url,
          } = onMessageData.data.data;
          store.dispatch({
            type: "START_CONNECTION",
            payload: {
              sessionId: customer_session_id,
              siteCode: site_code,
              apiPath: api_path,
              apiUrl: api_url,
            },
          });
        }
        break;
      case "close_transfer":
        if (onMessageData.data) {
          const { close_delay_millis, url } = onMessageData.data;
          if (close_delay_millis !== undefined)
            setTimeout(() => {
              if (url) window.open(url, "_self");
              else {
                store.dispatch({ type: "MINIMIZE_WINDOW" });
                store.dispatch({ type: "CLEAR_MESSAGES" });
                store.dispatch({ type: "START_CONNECTION", payload: null });
              }
            }, close_delay_millis);
        }

        break;

      default:
        break;
    }
  },
};
