import React, { useState, useCallback, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { actions, connect } from "client-page";
import { AppModeTypes } from "../../shared/modes";
import api from "../../server/rest/api";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { ReactComponent as Logo } from "./Logo.svg";

function KpCounselDelayModal({
  closeModal,
  modalId,
  customerId,
  channelId,
  modeType,
}) {
  const firstFocusRef = useRef();
  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, [firstFocusRef.current]);
  const handleClose = useCallback(() => {
    closeModal(modalId);
  }, [closeModal, modalId]);

  const handleReservate = useCallback(async () => {
    //sendMessage({ message:'상담예약' });
    try {
      api.kakaopaySendMessage({
        customerId,
        channelId,
      });
    } catch (e) {
      console.error(e);
    }
    closeModal(modalId);
  }, [channelId, closeModal, customerId, modalId]);

  return (
    <FloatingContainer>
      <Container>
        <Logo title="DB손해보험"style={{ paddingTop: 12 }} />
        <Description>
          고객님, 상담예약 남겨주시면
          <br />
          전문상담원이 연락드리도록 하겠습니다.
          <br />
        </Description>
        <Bottom>
          <LeftButton ref={firstFocusRef} onClick={handleReservate}>
            상담예약
          </LeftButton>
          <Button onClick={handleClose}>취소</Button>
        </Bottom>
      </Container>
    </FloatingContainer>
  );
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: calc(50% - 50px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing:border-box;
  width:320px;
  height: 232px;
  padding: 16px;
  border-radius: 16px;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  text-align:center;
`;

const Description = styled.div`
  color:${CUSTOM_PALETTE.GRAY_900};
  font-size: 16px;
  line-height:24px;
  font-weight:700;
`;

const Bottom = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
`;
const LeftButton = styled.button`
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.GRAY_200};
  color:${CUSTOM_PALETTE.GRAY_800};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const Button = styled.button`
  margin-left:8px;
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.PRIMARY_MAIN};
  color:${DB_COLORS.DB_WHITE};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const mapStateToProps = (state) => ({
  customerId: state.server.customerId,
  channelId: state.system.channelId,
  modeType: state.system.mode.Type,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: actions.closeModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KpCounselDelayModal);
