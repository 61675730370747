import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { elements, utils } from "client-page";
import { CUSTOM_PALETTE } from "../../theme";

const { requestThrottle } = utils;
const { BalloonContainer, BalloonBaseContent } = elements.messageBalloon;

export default function ButtonMessage({ message }) {
  const text = message.data.text;
  const image = message.data.image;
  const buttons = message.data.buttons;
  const disabled = message.disabled;

  const [checked, setChecked] = useState(null);

  return (
    <Fragment>
      {(text || image || buttons) && (
        <BalloonBox>
          <BalloonContainer message={message}>
            <BalloonBaseContent image={image} text={text} disabled={disabled} />
            <ButtonBox className="ButtonBox">
              {buttons.map((button, index) => {
                return (
                  <Button
                    disabled={disabled}
                    className="content-button"
                    key={index}
                    onClick={() => {
                      if (button.run) requestThrottle(button.run);
                      setChecked(index);
                    }}
                    title={button.desc ? button.desc : button.label}
                  >
                    {button.label}
                  </Button>
                );
              })}
            </ButtonBox>
          </BalloonContainer>
        </BalloonBox>
      )}
    </Fragment>
  );
}
const BalloonBox = styled.div`
  width: 100%;
`;

const ButtonBox = styled.div`
  display:flex;
  flex-direction:column;
  padding: 0px 16px 8px 16px;
`;
const Button = styled.button`
  width: calc(100%);
  font-size:14px;
  font-weight:700;
  color: ${CUSTOM_PALETTE.GRAY_900};
  margin-bottom:8px;
  border-radius:8px;
  border: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  background-color: ${CUSTOM_PALETTE.GRAY_100};
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
  :disabled {
    color: ${CUSTOM_PALETTE.GRAY_500};
  }
  line-height:20px;
  padding: 8px 16px;
`;
