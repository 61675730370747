const SESSION_STORE_KEY = "auth_data";

let sessionId = getUserData();

function clearUserSession() {
  //window.localStorage.removeItem(SESSION_STORE_KEY);
}

function setSessionId(authData) {
  //window.localStorage.setItem(SESSION_STORE_KEY, JSON.stringify(authData));
  sessionId = authData;
}

function getSessionId() {
  return sessionId;
}

function getUserData() {
  // if (window.localStorage.auth_data) {
  //   const result = JSON.parse(window.localStorage.auth_data);
  //   return result;
  // }

  return null;
}

const AuthUtils = {
  getSessionId,
  setSessionId,
  clearUserSession,
  getUserData,
};

export { AuthUtils };
