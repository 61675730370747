import { GetRegisteredComponentMap, RegisterComponentMap } from "client-page";
import React from "react";
import CloseWindowModal from "./CloseWindowModal";
import CounselEnterModal from "./CounselEnterModal";
import CounselCloseModal from "./CounselCloseModal";
import CounselWaitModal from "./CounselWaitModal";
import IframeModal from "./IframeModal";
import RatingModal from "./RatingModal";
import KpCounselDelayModal from "./KpCounselDelayModal";
import EdmsImageUploadModal from "./EdmsImageUploadModal";
import AlarmModal from "./AlarmModal";
import ImageModal from "./ImageModal";

const orgCompMap = GetRegisteredComponentMap("Modal");
const compMap = {
  ...orgCompMap,
  alarm: AlarmModal,
  counselWait: CounselWaitModal,
  counselEnter: CounselEnterModal,
  counselClose: CounselCloseModal,
  kpCounselDelay: KpCounselDelayModal,
  rating: RatingModal,
  closeWindow: CloseWindowModal,
  iframe: IframeModal,
  edmsImageUpload: EdmsImageUploadModal,
  image:ImageModal
};

RegisterComponentMap("Modal", compMap);
