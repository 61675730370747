import { GetRegisteredComponentMap, RegisterComponentMap } from "client-page";
import React from "react";
import AutocompleteText from "./AutocompleteText";

const MyComp = ({ data }) => {
  return <div>{data}</div>;
};

const orgCompMap = GetRegisteredComponentMap("AutoComplete");
const compMap = {
  ...orgCompMap,
  text: AutocompleteText,
  statGraph: AutocompleteText,
};

RegisterComponentMap("AutoComplete", compMap);
