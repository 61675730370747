import {
  IReducerDef,
  GetRegisteredReducerDef,
  RegisterReducerDef,
  constants,
  utils,
  actions,
  types,
  env,
  sender,
  generators,
} from "client-page";

import { AnyAction } from "redux";
import {
  put,
  call,
  select,
  takeEvery,
  take,
  cancel,
  fork,
} from "redux-saga/effects";
import { Action, createAction } from "redux-actions";
import api, { isResultOK } from "../../server/rest/api";
import * as rest from "../../server/rest";
import io from "../../server/socket.io";
import { AuthUtils } from "../../server/auth";
import { getCookie } from "../../server/cookie";
import { createDispatchHook } from "react-redux";
import { GuideMessageData } from "client-page/dist/shared/types";
import { SendMessageProp } from "client-page/src/redux/reducers/server";
import { VoidExpression } from "typescript";
import { CounselState, resetCounselState } from "./counselor";
import { resetApiUrl } from "../../server/url";
import { AppModeTypes } from "../../shared/modes";
import { setChannelId } from "./system";
const {
  loadMessages,
  addMessage,
  modifyMessage,
  setCustomerId,
  pushUserInput,
  markChatbotProcessing,
} = actions;
const { logger } = utils;

const { RoleType, CookieName } = constants;
const { getUUID, time } = utils;
type AnyMessage = types.AnyMessage;
const TAG = "redux/reducer/server";
interface ServerState {
  connection: boolean;
  polling: boolean;
}
const MINIMUM_LOAD_TIME = 1000;
export const SEND_TIMEOUT = 30 * 1000;
export const SEND_UPDATE_TIMEOUT = 300;
export const CLIENT_POLLING_INTERVAL = 20 * 1000;

interface IConnectionInfo {
  sessionId: string;
  API_GetSessionId: {
    type: string;
    url: string;
    body?: any;
    query?: string;
  };
}
// export const setConnectionInfo = createAction<IConnectionInfo>(
//   "SET_CONNECTION_INFO"
// );
// export const waitCounselor = createAction<string>("WAIT_COUNSELOR");
export interface StartTriggerProp {
  trigger_id: string;
  connection: boolean;
}

export const startTrigger = createAction<StartTriggerProp>("START_TRIGGER");
export const setTrigger = createAction<string>("SET_TRIGGER");
export const transferSite = createAction<void>("TRANSFER_SITE");
interface PostBackProp {
  address: string;
  body: any;
}
interface KosafServerState extends ServerState {
  triggerScenario?: string;
  isApiUrlReset?: boolean;
}

const orgReducerDef = GetRegisteredReducerDef("server") as IReducerDef<
  ServerState
>;
const reducerDef: IReducerDef<KosafServerState> = {
  ...orgReducerDef,
  initState: {
    ...orgReducerDef.initState,
    isApiUrlReset: false,
  },
  reducerMap: {
    ...orgReducerDef.reducerMap,
    SET_TRIGGER: (state, action: Action<string>) => {
      return {
        ...state,
        triggerScenario: action.payload,
      };
    },
    SET_IS_API_URL_RESET: (state, action: Action<boolean>) => {
      return {
        ...state,
        isApiUrlReset: action.payload,
      };
    },
  },
  sagaMap: {
    START_CONNECTION: function* (
      action: Action<
        | {
            siteCode: string;
            sessionId: string;
            apiUrl: string;
            apiPath: string;
          }
        | undefined
      >
    ) {
      yield put(actions.setLoading(true));
      if (io.manager.isOpened()) {
        let state = (yield select()) as any | undefined;
        const modeType = state.system.mode.type;
        if (modeType === AppModeTypes.COUNSEL) {
          yield take((action: AnyAction) => {
            if (action.type === "CHANGE_APP_MODE") return true;
            return false;
          });
        }
        let messages = state.message.messages.map((message: any) => ({
          ...message,
          disabled: true,
        }));
        if (messages.length > 0) messages[0].disabled = false;
        yield put(actions.loadMessages(messages));
        yield put(actions.finishConnection());
        const { apiUrl, apiPath, siteCode, sessionId } = action.payload ?? {};
        if (siteCode && apiUrl && apiPath) {
          resetApiUrl({
            siteCode,
            apiUrl,
            apiPath,
          });
          yield put({ type: "SET_IS_API_URL_RESET", payload: true });
        } else {
          resetApiUrl();
          yield put({ type: "SET_IS_API_URL_RESET", payload: false });
        }
        if (sessionId) AuthUtils.setSessionId(sessionId);
      } else {
        const { sessionId } = action.payload ?? {};
        if (sessionId) {
          AuthUtils.setSessionId(sessionId);
        }
      }

      const state = (yield select()) as any | undefined;
      let channel_id = state.system.channelId;

      yield put(setChannelId(channel_id));
      rest.setChannelId(channel_id);
      let referer_page: string | undefined =
        state?.system?.pluginConfig?.RefererPage;

      let event_code: string | null = "";
      try {
        let searchParams = new URL(window.parent?.location?.href).searchParams;
        event_code = searchParams.get("event_code");
      } catch (e) {}

      try {
        const startTime = Date.now();
        let state = (yield select()) as any | undefined;
        const result = (yield call<any>(api.customerStart, {
          parentHost: state.system.parentHost,
          channel_id,
          event_code,
          referer_page,
        })) as any;
        // customer start 를 통해 authToken(session id) 을 가져오면 authToken 을 저장하고, 웹소켓 연결을 시도한다.

        const newAuthToken = result.customerSessionId;
        AuthUtils.setSessionId(newAuthToken);
        // triggerData 가 있으면 호출
        if (result.transferVisible && !state.server.isApiUrlReset)
          yield put({ type: "SET_ENABLE_SITE_TRANSFER", payload: true });
        else yield put({ type: "SET_ENABLE_SITE_TRANSFER", payload: false });

        // 웹소켓 연결
        io.manager.open(newAuthToken);
        const connectAction = (yield take("SET_CONNECTION")) as any;
        if (connectAction.payload !== true) {
          throw "connect failed";
        }
        if (state.server.triggerScenario) {
          yield call(api.customerTrigger, {
            connection: false,
            triggerData: { trigger_id: state.server.triggerScenario },
          });
        }
        // Socket 연결에 성공하면 counselGreeting API 를 요청함(인사말)
        const counsel = (yield call(api.counselGreeting)) as any;
        yield put(resetCounselState(counsel));
        // 현재 세션의 과거 메시지를 가져온다.
        // 시나리오로 실행할 때에는 과거 메시지를 로드하지 않는다.
        // 상담 중에 재시작한 경우에는 대화 내용을 로드하여 준다.
        if (
          !state.server.triggerScenario ||
          counsel.state === CounselState.Counseling ||
          channel_id === "TM_KP"
        )
          yield put(actions.loadCustomerMessages(false));

        // 폴링 타이머 구동
        yield put({ type: "START_POLLING" });
        console.log("startConnection finished");
        const diff = Date.now() - startTime;
        if (diff < MINIMUM_LOAD_TIME) {
          yield call(generators.sleep, MINIMUM_LOAD_TIME - diff);
        }
        yield takeEvery("ADD_MESSAGE", function* (action: Action<AnyMessage>) {
          let state = (yield select()) as any | undefined;
          const message = action.payload;
          if (state?.system?.focus && message && !message.isSent) {
            yield put({ type: "MESSAGE_READ", payload: message.id });
          }
        });

        yield put(actions.setLoading(false));
        yield put(actions.successConnection());
      } catch (e) {
        yield put(
          actions.pushModal({
            modalType: "alarm",
            modalProps: {
              text:
                "시스템 점검 중 입니다.<br/>서비스 이용에 불편을 드려 죄송합니다.",
            },
          })
        );
        console.error("startConnection error", e.errorMessage);
      }
    },
    MESSAGE_READ: function* (action: Action<string>) {
      try {
        yield call(api.customerMessageRead, action.payload);
        //debugger;
      } catch (e) {
        console.error(e);
        //debugger;
      }
    },
    // SET_TRIGGER: function *(action:Action<string>){
    // },
    START_TRIGGER: function* (action: Action<StartTriggerProp>) {
      yield call(api.customerTrigger, {
        connection: action.payload.connection,
        triggerData: { trigger_id: action.payload.trigger_id },
      });
    },
    FINISH_CONNECTION: function* (action) {
      try {
        yield put({ type: "STOP_POLLING" });
        io.manager.close();
        AuthUtils.setSessionId(null);
        console.log("finishConnection finished");
      } catch (e) {
        console.error("finishConnection error", e.errorMessage);
      }
    },
    CHECK_CONNECTION: function* (action) {
      let state = (yield select()) as any | undefined;
      if (!state) {
        return;
      }
      const messages: AnyMessage[] = state.message.messages;
      if (messages) {
        for (let i = messages.length - 1; i >= 0; i--)
          if (!messages[i].isSent) {
            yield put({ type: "MESSAGE_READ", payload: messages[i].id });
            break;
          }
      }

      if (!state.server.connection) {
        io.manager.reopen();
      }
    },
    START_POLLING: function* (action) {
      let state = (yield select()) as any | undefined;
      if (!state) {
        return;
      }
      if (state.server.polling) {
        return;
      }
      yield put({ type: "PUSH_POLLING" });

      let pollingCnt = 0;
      do {
        if (pollingCnt > 3)
          yield call(generators.sleep, CLIENT_POLLING_INTERVAL);
        else yield call(generators.sleep, CLIENT_POLLING_INTERVAL / 4);
        pollingCnt++;
        yield put({
          type: "DO_POLLING",
        });
        state = (yield select()) as any | undefined;
      } while (state && state.server.polling);
    },
    DO_POLLING: function* (action) {
      try {
        const result = (yield call(api.customerPolling)) as undefined | any;
        if (result) {
          for (const i in result) {
            yield put(loadMessages(result[i]));
          }
        }
      } catch (e) {
        console.error("polling error");
      }
    },
    LOAD_CUSTOMER_MESSAGES: function* (action: Action<boolean>) {
      const byLastId = action.payload;

      //logger.debug(TAG, "customerMessageList()", "byLastId:", byLastId);
      let lastId = "";

      if (byLastId) {
        const state = (yield select()) as any | undefined;
        if (!state) {
          throw 0;
        }
        let messages = state.message.messages;

        let lastMessage = messages[messages.length - 1];
        if (lastMessage) {
          lastId = lastMessage.id;
          //logger.debug(TAG, "lastId:", lastId);
        } else {
          //logger.debug(TAG, `last message doesn't exist`);
        }
      }
      try {
        const result = (yield call(api.customerMessageList, {
          count: 10000,
          order: "asc",
          last_id: lastId,
        })) as any;

        if (result.length > 0) yield put(loadMessages(result));
      } catch (error) {
        // TODO
        // 호출 실패 처리
      }
    },
    START_INPUT: function* () {
      try {
        yield call(api.customerInputStart);
      } catch (error) {
        // TODO
        // 호출 실패 처리
      }
    },
    END_INPUT: function* () {
      try {
        yield call(api.customerInputEnd);
      } catch (error) {
        // TODO
        // 호출 실패 처리
      }
    },
    SEND_MESSAGE: function* (action: Action<SendMessageProp>) {
      const messageText = action.payload.message;
      const messageContext = action.payload.context;
      // TODO
      // 필요 한 거, 필요하지 않은 거 정리
      try {
        const result = (yield call(
          api.customerMessageSend,
          messageText,
          messageContext
        )) as any;
        if (!result) return;
        yield put(setCustomerId(result.customerId));
      } catch (error) {}
    },
    SEND_AUTOCOMPLETE_QUERY: function* (action) {
      try {
        const query = action.payload;
        const list = (yield call(api.customerAutoComplete, query)) as any;

        yield put({
          type: "SET_AUTOCOMPLETE_LIST",
          payload: {
            query: query,
            list,
          },
        });
        yield put({
          type: "SET_AUTOCOMPLETE_SELECTED",
          payload: undefined,
        });
      } catch (e) {
        yield put({
          type: "SET_AUTOCOMPLETE_LIST",
          payload: {
            query: "",
            list: [],
          },
        });
        yield put({
          type: "SET_AUTOCOMPLETE_SELECTED",
          payload: undefined,
        });
      }
    },
    TRIGGER_SCENARIO: function* (action) {
      try {
        if (action.payload.scenarioId) {
          yield put({
            type: "FLUSH_MESSAGE_LIST",
          });
          yield call(api.customerPostbackScenario, {
            scenarioId: action.payload,
          });
        }
      } catch (e) {}
    },
    POST_BACK: function* (action: Action<PostBackProp>) {
      try {
        yield call(api.customerPostBack, action.payload);
      } catch (e) {}
    },
    GO_BACK_HOME: function* (action: Action<void>) {
      try {
        yield call(api.counselHome);
      } catch (e) {}
      //yield put(receiveChatbotMessage(message, 1000));
    },

    TRANSFER_SITE: function* (cation: Action<void>) {
      try {
        yield put(actions.setLoading(true));
        const {
          customer_session: { customer_session_id },
          site_code,
          api_path,
          api_url,
        } = (yield call(api.customerTransfer)) as any;
        yield put(actions.setLoading(false));
        // yield put({
        //   type: "START_CONNECTION",
        //   payload: {
        //     sessionId: customer_session_id,
        //     siteCode: site_code,
        //     apiPath: api_path,
        //     apiUrl: api_url,
        //   },
        // });
      } catch (e) {
        yield put(actions.setLoading(false));
      }
    },
  },
};

// const reducerDef = {
//   ...orgReducerDef,
//   sagaMap: {
//     ...orgReducerDef.sagaMap,
//     START_CONNECTION: function* (action) {
//       console.log("start app: overloaded saga.");
//       if (true) yield call(orgReducerDef.sagaMap.START_CONNECTION, action);
//       else {
//       }
//       console.log("start app: overloaded saga end.");
//     },
//   },
// };

RegisterReducerDef(reducerDef);
