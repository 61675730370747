import { DefaultTheme as theme, createTheme } from "client-page";

export const DB_COLORS = {
  DB_GREEN: "#00864B",
  DB_CHATBOT_GREEN: "#00854a",
  DB_MEDIUM_GREEN: "#17593D",
  DB_DARK_GREEN: "#254C3A",
  DB_GREEN_GRADIENT: "#009559",
  DB_YELLOW_GREEN_GRADIENT: "#8DC63F",
  DB_VIVID_YELLOW_GREEN: "#c9DC04",
  DB_WHITE: "white",
  DB_PASTEL_GREEN: "#CCEFD8",
  DB_GRAY_GREEN: "#DDE6D9",
  DB_GRAY_BLUE: "#94B1CF",
  DB_PASTEL_BLUE: "#C5DCEA",
  DB_DARK_GRAY: "#535353",
  DB_BIG_TITLE: "#1A1A1A",
  DB_SUB_TITLE: "#252525",
  DB_CONTENTS1: "#535353",
  DB_CONTENTS2: "#767676",
  DB_ACCENT1: "#157941",
  DB_ACCENT2: "#CA4C07",
  DB_ACCENT3: "#617D0E",
  DB_ACCENT4: "#1973BF",
  DB_BG_GRAY_GREEN: "#F3F7F6",
};
export const CUSTOM_PALETTE = {
  PRIMARY_MAIN: "#238d50",
  PRIMARY_LIGHT: "#35CC76",
  PRIMARY_DARK:"#257E4B",
  PRIMARY_CHIP_BUTTON: "#EAF6EF",
  STRONG: "#F44336",
  SECONDARY_MAIN: "#d#7200",
  GRADIENT_START: "#35CC77",
  GRADIENT_END: "#1EA8E1",
  GRAY_50: "#FAFAFA",
  GRAY_100: "#F5F5F5",
  GRAY_200: "#EEEEEE",
  GRAY_300: "#E0E0E0",
  GRAY_400: "#BDBDBD",
  GRAY_500: "#9E9E9E",
  GRAY_600: "#757575",
  GRAY_700: "#616161",
  GRAY_800: "#424242",
  GRAY_900: "#212121",
  DARK: "#000000",
};

export default createTheme({
  texts: {
    "message.chatbot.title": "챗봇",
    "message.counselor.title": "채팅상담사",
    "input.none.title": "DB다이렉트",
    "center.bottom.textarea.placeholderDisabledText":
      "지금은 메시지를 입력할 수 없습니다.",
  },
  colors: {
    RECEIVE_MESSAGE_BACKGROUND: DB_COLORS.DB_WHITE,
    RECEIVE_MESSAGE_TEXT: CUSTOM_PALETTE.GRAY_900,
    SEND_MESSAGE_BACKGROUND: CUSTOM_PALETTE.PRIMARY_MAIN,
    SEND_MESSAGE_TEXT: DB_COLORS.DB_WHITE,
    ROWS_BACKGROUND: CUSTOM_PALETTE.GRAY_50,
    AUTOCOMPLETE_QUERY_HIGHLIGHT: CUSTOM_PALETTE.PRIMARY_DARK,
  },
  images: {
    CHATBOT_ICON: "./images/db_avatar.svg",
    COUNSELOR_ICON: "./images/counselor_icon.png",
    HEADER_ICON: "./images/logo.jpg",
    HEADER_COUNSEL_BUTTON: "./images/counselor_icon.png",
  },
  styles: {
    HEADER_CONTAINER: `
      width:100%;
      background-color: ${CUSTOM_PALETTE.GRAY_50};
    `,
    MESSAGE_LEFT_PAD: `
        ${theme.STYLES.MESSAGE_LEFT_PAD}
        margin-right:-10px;
                `,
    MESSAGE_NAME_CONTAINER: `
        ${theme.STYLES.MESSAGE_NAME_CONTAINER}
        height:13px;
        margin : 6px 0px 10px 12px;
        font-size:13px;
        line-height:13px;
        font-weight:bold;
        color:${CUSTOM_PALETTE.GRAY_900};`,
    MESSAGE_ICON_CONTAINER: `
            width: 32px;
            height: 32px;
            box-sizing: border-box;
            border-radius: 16px;
            border: solid 1px transparent;
            background-image: linear-gradient(#F5F5F5,#F5F5F5), linear-gradient(90deg,#35CC76 0%,#1EA8E1 100%);
            background-origin: border-box;
            background-clip: content-box,border-box;
            display:flex;
            justify-content:center;
            align-items: center;
            overflow: overlay;
        `,
    MESSAGE_ICON: `
          ${theme.STYLES.MESSAGE_ICON} 
          border-radius: 0px;
          height:30px;
          width:30px;
          background-color:white;
        `,
    MESSAGE_BUTTONS: `
    ${theme.STYLES.MESSAGE_BUTTONS}
    width: 230px;
    margin-right: 5px;

    & > button {
      max-width:none;
      border: solid 1px ${DB_COLORS.DB_CHATBOT_GREEN};
      background-color:${DB_COLORS.DB_WHITE};
      color:${DB_COLORS.DB_CONTENTS1};
      transition: all 0.3s;
      box-sizing: border-box;
      box-shadow: none;
      line-height:21px;
      padding: 8px 16px;
      :disabled{
        color:${DB_COLORS.DB_GRAY_GREEN};
      }
      > div {
        font-size:14px;
        padding-left: 5px;
        padding-right: 10px;
      }
      > svg {
        fill:${DB_COLORS.DB_CHATBOT_GREEN};
    }
    `,
    MESSAGE_QREPLYS: `
    `,
    MESSAGE_BALLOON_TEXT: `
        ${theme.STYLES.MESSAGE_BALLOON_TEXT}
        padding:0px 16px 12px 16px;
        >div{
          font-size:14px;
        >a{
          color: ${DB_COLORS.DB_GREEN};
        }
        >strong{
          font-size:14px;
          font-weight:700;
          line-height:24px;
          color:${CUSTOM_PALETTE.STRONG};
        }
        >em{
          font-size:14px;
          font-weight:700;
          color:${CUSTOM_PALETTE.PRIMARY_MAIN};
        }
        >hr{
            border-top: 1px solid ${CUSTOM_PALETTE.GRAY_300};
        }
      }
      `,

    MESSAGE_BALLOON_CONTAINER: `
    ::before {
      display:none;
    }
    border-radius: 8px;
    max-width: 80%;
      `,
    RECEIVE_MESSAGE_BALLOON_CONTAINER: `
      border: solid 1px #E0E0E0;
    `,
    SEND_MESSAGE_BALLOON_CONTAINER: `
      ::before {
        display:none;
      }
      border-radius: 8px;
      font-weight:700;
    `,
    MESSAGE_DATE_CONTAINER: `
          font-size: 12px;
          line-height:12px;
          padding-top:8px;
          color: ${CUSTOM_PALETTE.GRAY_800};
        `,

    MODAL_HEADER: `
            ${theme.STYLES.MODAL_HEADER}
            background-color: #00864b;
            color:white;
    
            `,
    INPUTBOX_CONTAINER: `
      border-top: 1px solid ${CUSTOM_PALETTE.GRAY_300};
      background: ${DB_COLORS.DB_WHITE};
      box-sizing: border-box;
      min-height:40px;
      padding: 7.5px 11px;
    `,
    INPUTBOX_TEXTINPUT: `
      ${theme.STYLES.INPUTBOX_TEXTINPUT}
      resize: none;
      border: none;
      margin: 12px;
      padding:0px;
      font-size:14px;
      line-height:14px;
      font-weight:700;
      background-color:transparent;
      height:16px;
      min-height:16px;
      overflow-y:hidden;
      color:${CUSTOM_PALETTE.GRAY_900};
      &::placeholder {
        color:${CUSTOM_PALETTE.GRAY_400};
      }
      &:focus{
        outline:none;
      }
    `,
    AUTOCOMPLETE: `
      position: absolute;
      display: flex;
      flex-flow: column wrap;
      bottom: 60px;
      width: 100%;
      background-color: ${DB_COLORS.DB_WHITE};
      >div:first-child{
        border-radius: 16px 16px 0px 0px;
      }
      border-radius: 16px 16px 0px 0px;
      overflow: hidden;
      box-shadow: 5px 5px 20px 0px #0000001A;
    `,
    AUTOCOMPLETE_TERMS: `
      box-sizing:border-box;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      position:relative;
      font-size:14px;
      font-weight:700;
      text-align:center;
      color:${CUSTOM_PALETTE.GRAY_800};
      padding: 21px 16px;
      >span {
        font-size:14px;
        font-weight:700;
      }
      border-top : solid 1px ${CUSTOM_PALETTE.GRAY_300};
    `,
  },
});
// const COLORS = {
//     ...theme.COLORS,
//     primaryColor = {}

// }
// const CONTAINER_STYLE = {

//     HEADER : `
//         ${Default.CONTAINER_STYLE.HEADER}
//         background-color:black;
//     ` ,
// }
