import { GetRegisteredComponentMap, RegisterComponentMap } from "client-page";
import GuideInput from "./guideinput";
import TextInput from "./textinput";

const orgCompMap = GetRegisteredComponentMap("UserInput");
const compMap = {
  ...orgCompMap,
  Text: TextInput,
  Guide:GuideInput
};

RegisterComponentMap("UserInput", compMap);
