import { connect, actions } from "client-page";
import React, { useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import api from "../../server/rest/api";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { AppModeTypes } from "../../shared/modes";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { ReactComponent as Logo } from "./Logo.svg";

function RatingModal({
  modalId,
  closeModal,
  minimizeWindow,
  useMinimize,
  changeAppMode,
}) {
  const [ratingList] = useState([1, 2, 3, 4, 5]);
  const [currentRating, setCurrentRating] = useState(5);

  const handleClick = useCallback((rating) => {
    setCurrentRating(rating);
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      await api.customerCounselStar({ star: currentRating });
      // TODO: 만족도 평가 후 모달 종료
    } catch (e) {}
    if (useMinimize) minimizeWindow();
    closeModal(modalId);
    changeAppMode({ type: AppModeTypes.DEFAULT });
  }, [currentRating]);

  return (
    <FloatingContainer>
      <Container>
        <Logo title="DB손해보험"style={{ padding: "12px 0px 20px" }} />
        <Description>상담 만족도 조사</Description>
        <Text>
          상담에 만족 하셨나요?
          <br />
        </Text>
        <RatingList>
          {ratingList.map((rating) => (
            <Rating onClick={() => handleClick(rating)}>
              {rating <= currentRating ? (
                <AiFillStar size="50" color={DB_COLORS.DB_CHATBOT_GREEN} />
              ) : (
                <AiOutlineStar size="50" color={DB_COLORS.DB_CHATBOT_GREEN} />
              )}
            </Rating>
          ))}
        </RatingList>
        <Bottom>
          <Button disabled={!currentRating} onClick={handleSubmit}>
            제출
          </Button>
        </Bottom>
      </Container>
    </FloatingContainer>
  );
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: calc(50% - 50px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing:border-box;
  width:320px;
  padding: 16px;
  border-radius: 16px;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  text-align:center;
`;

const Description = styled.div`
  color:${CUSTOM_PALETTE.GRAY_900};
  font-size: 16px;
  line-height:24px;
  font-weight:700;
`;

const Bottom = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
`;

const Button = styled.button`
  margin-left:8px;
  flex: 1;
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.PRIMARY_MAIN};
  color:${DB_COLORS.DB_WHITE};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
`;

const RatingList = styled.div`
  text-align: center;
  padding: 52px 0px 60px;
`;
const Rating = styled.div`
    display: inline-block;
    width: 50px;
    height: 50px;
`;
const Text = styled.div`
  font-size: 14px;
  line-height:24px;
  color: ${CUSTOM_PALETTE.GRAY_900};
  `;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: actions.closeModal,
      minimizeWindow: actions.minimizeWindow,
      changeAppMode: actions.changeAppMode,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingModal);
