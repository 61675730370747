import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import { actions, utils } from "client-page";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";

const { requestThrottle, isBelowIE10 } = utils;

const { postBack, sendMessage } = actions;

function GuideMessage({ message, setWidthOption }) {
  // useEffect(() => {
  //   setWidthOption("WIDE");
  // }, []);
  const title = message.data.title;
  const [guideItems, setGuideItems] = useState(
    message.data.items.map((item) => ({
      ...item,
      checked: false,
    }))
  );

  const disabled = message.disabled;

  const handleItemClick = (index) => {
    const run = guideItems[index].run;
    if (run) requestThrottle(run);
    setGuideItems(
      guideItems.map((item, idx) => {
        if (index === idx) return { ...item, checked: true };
        else return { ...item, checked: false };
      })
    );
  };

  return (
    <Container>
      <GuideItemList>
        {guideItems.map((item, index) => (
          <GuideItem
            key={index}
            item={item}
            onClick={() => handleItemClick(index)}
            disabled={disabled}
          />
        ))}
      </GuideItemList>
    </Container>
  );
}

function GuideItem({ item, onClick, disabled }) {
  return (
    <GuideItemPane checked={item.checked} disabled={disabled} onClick={onClick} title={item.desc}>
      <Title>{item.label}</Title>
    </GuideItemPane>
  );
}

const mapStateToProps = (state) => ({
  width: state.data.innerWidth,
  height: state.data.innerHeight,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postBack, sendMessage }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideMessage);

const Container = styled.div`
  ${(props) => props.theme.STYLES.GUIDE_CONTAINER}
  padding:0px;
`;

const GuideItemList = styled.div`
  margin-top:-8px;
  display: flex;
  flex-wrap: wrap;
`;

const GuideItemPane = styled.button`
  padding: 12px 15px;
  border-radius: 32px;
  border: solid 1px ${CUSTOM_PALETTE.PRIMARY_LIGHT};
  margin: 8px 8px 0px 0px;
  height: 40px;
  cursor:pointer;
  background-color: white;
  transition: all 0.3s;
  color: ${CUSTOM_PALETTE.GRAY_800}
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
  :disabled {
    color: ${CUSTOM_PALETTE.GRAY_500};
  }
`;

const Title = styled.div`
  font-size: 14px;
  height:14px;
  line-height:14px;
  font-weight: bold;
`;
