import { actions, RegisterComponentMap, connect, utils } from "client-page";
import React, {
  useCallback,
  useRef,
  useMemo,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { HiDotsCircleHorizontal, HiThumbUp } from "react-icons/hi";
import { BiTransferAlt } from "react-icons/bi";
import uuid from "uuid/v4";
import { AppModeTypes } from "../../shared/modes";
import api from "../../server/rest/api";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { servicesVersion } from "typescript";
import { ReactComponent as CalcButtonIcon } from "./CalcButtonIcon.svg";
import { ReactComponent as CounselButtonIcon } from "./CounselButtonIcon.svg";
import { ReactComponent as Logo } from "./Logo.svg";

function Header({
  isMinimized,
  pushModal,
  modeType,
  useMinimize,
  channelId,
  transferSite,
  enableSiteTransfer,
  triggerScenario,
}) {
  const [bCounsel, setCounsel] = useState(modeType === AppModeTypes.COUNSEL);
  const [openBottom, setOpenBottom] = useState(false);


  const handleCloseBottom = useCallback(()=>{
    setOpenBottom(false);
    const tbtn = document.getElementById('transfer_btn');

    tbtn.focus();

  },[])


  useEffect(() => {
    setCounsel(modeType === AppModeTypes.COUNSEL);
  }, [modeType]);
  const firstFocusRef = useRef(null);

  useEffect(() => {
    //챗봇 아이프레임에 포커스 진입시 close button에 포커스 
    const check = (e) => {
      if(firstFocusRef.current)
        firstFocusRef.current.focus();
    }
    window.addEventListener('focus', check);
    return () => {
      window.removeEventListener('focus', check);
    }
  }, [])
  const dbCar = useMemo(
    () => (channelId ? channelId.indexOf("CM") >= 0 : false),
    [channelId]
  );
  const kp = useMemo(() => (channelId ? channelId.indexOf("KP") >= 0 : false), [
    channelId,
  ]);

  const hasBottom = useMemo(() => {
    let hasBottom;
    switch (triggerScenario) {
      case "np_ba":
      case "bc_ba":
      case "bk_ba":
      case "bd_ba":
        hasBottom = false;
        break;
      default:
        hasBottom = !dbCar && !kp;
    }
    return hasBottom;
  }, [triggerScenario, dbCar, kp]);

  function handleConsult() {
    api.customerCounselRequest();
  }
  function handleTransfer() {
    transferSite();
  }
  const handleCloseClick = useCallback(() => {
    pushModal({ modalType: "closeWindow" });
  }, [pushModal]);
  const handleCounselCloseClick = useCallback(() => {
    pushModal({ modalType: "counselClose" });
  }, [pushModal]);
  useEffect(() => {
    const preventGoBack = (e) => {
      window.history.pushState(null, "", window.location.href);
      handleCloseClick();
    };
    //window.focus();
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", preventGoBack);

    return () => window.removeEventListener("popstate", preventGoBack);
  }, [handleCloseClick]);
  return (
    <Container className="header-container">
      <TopContainer>
        <Logo title="DB손해보험" />
        <Title></Title>

        {/*!bCounsel && */}
        {/*!bCounsel && */}
        <IconButtonContainer>
        <CloseButton
          id="chatbot_close"
          ref={firstFocusRef}
          title="닫기"
          onClick={handleCloseClick}
        >
          <MdClose className="icons" title="닫기" size="20" />
        </CloseButton>
        {!kp && bCounsel && (
          <StopCounselButton
            id="chatbot_close"
            title="상담취소"
            className="buttons"
            size="15"
            color={DB_COLORS.DB_CHATBOT_GREEN}
            onClick={handleCounselCloseClick}
          >
            상담취소
          </StopCounselButton>
        )}
        {enableSiteTransfer && (
          <TransferButton
            onClick={handleTransfer}
          >
           채팅상담사 연결
          </TransferButton>
        )}
        {hasBottom&&
          <TransferButton
            id='transfer_btn'
            open={openBottom}
            onClick={() => setOpenBottom((open) => !open)}
          >
            채팅상담사 연결
          </TransferButton>
        }
        </IconButtonContainer>
      </TopContainer>
      {/* <Belt /> */}
      {/* <Divider /> */}
      <BottomConatiner open={openBottom}>
        {openBottom &&
        <>
        <BottomButtonContainer>
          <BottomButton
            {...utils.TaborderClickCtrl(() => {
              handleConsult();
              //api.cusotomerURLCustomRequest({ type: "prm_cal" });
              // pushModal({
              //   modalType: "iframe",
              //   modalProps: {
              //     title: "보험료 계산",
              //     url:
              //       "https://www.dbinsure.kr/silson/?JEHUSA_CD=C5107&utm_source=cm_naver&utm_medium=cpc&utm_campaign=cm_pc&utm_content=silson&utm_term=DB%EB%8B%A4%EC%9D%B4%EB%A0%89%ED%8A%B8",
              //   },
              // });
            })}
          >
            <CounselButtonIcon />
            {"채팅 상담연결"}
          </BottomButton>
          <BottomButton
            {...utils.TaborderClickCtrl(() => {
              api.cusotomerURLCustomRequest({ type: "cnsl_rsvt" });
              // pushModal({
              //   modalType: "iframe",
              //   modalProps: {
              //     title: "보험료 계산",
              //     url:
              //       "https://www.dbinsure.kr/silson/?JEHUSA_CD=C5107&utm_source=cm_naver&utm_medium=cpc&utm_campaign=cm_pc&utm_content=silson&utm_term=DB%EB%8B%A4%EC%9D%B4%EB%A0%89%ED%8A%B8",
              //   },
              // });
            })}
          >
            <CalcButtonIcon />
            {"보험료 계산"}
          </BottomButton>
        </BottomButtonContainer>
        <CloseBottomButton onClick={handleCloseBottom}>
          닫기
        </CloseBottomButton>
        </>
    }
      </BottomConatiner>
    </Container>
  );
}
const TopContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  display:flex;
  flex-direction:row;
  align-items: center;
  padding: 0px 16px 0px 20px;
  border-bottom: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  background-color: white;
  position:relative;
  z-index:2;
`;

const Title = styled.div`
  flex-grow:1;
  color: white;
  height:24px;
  font-size: 15px;
  font-weight: 500;
  color: #00864b;
`;
const TransferButton = styled.button`
  height:30px;
  border-radius:4px;
  border: none;
  padding: 0px 12px;
  margin: 0;
  font-size:14px;
  line-height:14px;
  font-weight:700;
  ${(props) =>
    props.open
      ? `
      color:${CUSTOM_PALETTE.GRAY_700};
      background: ${CUSTOM_PALETTE.GRAY_100};
      `
      : `
      color:${CUSTOM_PALETTE.PRIMARY_MAIN};
      background: ${CUSTOM_PALETTE.PRIMARY_CHIP_BUTTON};
      `}

`;
const CloseButton = styled.button`
  width:24px;
  height:24px;
  padding:2px;
  border-radius:4px;
  border: none;
  margin: 0px 0px 0px 12px;
  background: ${CUSTOM_PALETTE.GRAY_100};
  color:${CUSTOM_PALETTE.GRAY_600};
`;
const BottomConatiner = styled.div`
  display:flex;
  flex-direction:column;
  border-radius: 0px 0px 16px 16px;
  overflow: auto;
  overflow-y: hidden;
  position:relative;
  z-index:1;
  box-shadow: 5px 5px 20px 0px #0000001A;
  transition: all 0.3s;
  height: 0px;
  ${(props) => props.open && `height: 96px;`}
`;
const BottomButtonContainer = styled.div`
  width: 100%;
  height: 64px;
  display:flex;
  flex-direction:row;
  border-bottom: solid 1px ${CUSTOM_PALETTE.GRAY_300};

`;
const Container = styled.div`
  width: 100%;
`;
const IconButtonContainer = styled.div`
    display:flex;
    flex-direction: row-reverse;
    align-items: center;

`;

const Consult = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${(props) => props.theme.IMAGES.HEADER_COUNSEL_BUTTON});
  background-size: 20px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
  cursor: pointer;
`;

// const Belt = styled.div`
//   width: 100%;
//   height: 10px;
//   background: linear-gradient(to right, #c8dc04, #00864b);
// `;

const Divider = styled.hr`

`;

const BottomButton = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex: 0 0 50%;
  height: 100%;
  border:none;
  border-left: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  background-color: white;
  line-height: 16px;
  font-size: 16px;
  font-weight: 700;
  color: #31A563;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.3s;
  & > svg{
    margin-right:4px;
  }
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;
const CloseBottomButton = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background:${CUSTOM_PALETTE.GRAY_100};
  border-radius: 0px 0px 16px 16px;
  width:100%;
  height:32px;
  flex: 0 0 32px;
  border: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  border-top:none;
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  color: ${CUSTOM_PALETTE.GRAY_800};
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const StopCounselButton = styled.button`
  border: none;
  border-radius: 5px;
  color: white;
  margin-right: 5px;
  font-size: 12px;
  padding: 3px 6px;
  background-color: ${DB_COLORS.DB_CHATBOT_GREEN};
  :hover {
    background-color: ${DB_COLORS.DB_YELLOW_GREEN_GRADIENT};
  }
`;

const mapStateToProps = (state) => ({
  isMinimized: state.window.isMinimized,
  useMinimize: state.window.useMinimize,
  modeType: state.system.mode.type,
  channelId: state.system.channelId,
  enableSiteTransfer: state.system.enableSiteTransfer,
  triggerScenario: state.server.triggerScenario,
});
const mapDispatchToProps = {
  goBackHome: actions.goBackHome,
  pushModal: actions.pushModal,
  closeModal: actions.closeModal,
  minimizeWindow: actions.minimizeWindow,
  sendMessage: actions.sendMessage,
  urlExtLink: actions.urlExtLink,
  transferSite: () => ({ type: "TRANSFER_SITE", payload: null }),
};

RegisterComponentMap("Header", {
  Default: connect(mapStateToProps, mapDispatchToProps)(Header),
});
