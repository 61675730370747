import React, {  } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MdClose } from "react-icons/md";
import actions from "../../redux/actions";
const { closeModal } = actions;

function ImageModal(props) {
  const { title, src, modalId, closeModal } = props;

  return (
    <Back>
      <Pane>
        <CloseButton
          onClick={() => {
            closeModal(modalId);
          }}
        >
          <MdClose size="20" title="닫기" color="black" />
        </CloseButton>
        {title && <Title>{title}</Title>}
        <Line />
        {src && (
          <ImageContainer>
            <Image src={src} />
          </ImageContainer>
        )}
      </Pane>
    </Back>
  );
}

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 5px;
  border: none;
  background-color: transparent;
`;

const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  font-size: 18px;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;
const Pane = styled.div`
  background-color: white;
  border-radius: 15px;
  margin: 20px;
  padding: 20px;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin-bottom: 10px;
  user-select: none;
  cursor: pointer;
  position: relative;
`;
const Title = styled.div`
  font-size: 18px;
  color: darkgray;
  padding: 10px 10px 15px;
  height: 18px;
`;

const Line = styled.div`
  border-top: 1px solid darkgray;
  margin-top: 8px;
  width: 100%;
`;
const ImageContainer = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  overflow: auto;
`;
const Image = styled.img`
  object-fit: cover;
`;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
