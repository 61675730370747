import React, {useRef,useEffect}from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actions } from "client-page";
import _ from "lodash";
import styled from "styled-components";

function AutocompleteText({
  highlight,
  text,
  onClick,
  onMouseOver,
  query: _query,
}) {

  let term = text.slice();
  let query = _query;

  if (_query && _query.length >= 1 && _query.charAt(0) === "#")
    query = _query.slice(1, _query.length);

  const splited = [];
  if (query) {
    const lenQuery = query.length;

    while (term.length > 0) {
      const idxOfQuery = term.indexOf(query);
      if (idxOfQuery < 0) {
        splited.push({ str: term });
        break;
      }
      splited.push({
        str: term.substr(0, idxOfQuery),
      });
      splited.push({
        str: term.substr(idxOfQuery, lenQuery),
        highlight: true,
      });
      term = term.substr(idxOfQuery + lenQuery);
    }
  } else splited.push({ str: query });

  return (
    <Container
      highlight={highlight}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      "
      {splited.map((sp) => {
        if (sp.highlight) return <HighlightWord>{sp.str}</HighlightWord>;
        else return sp.str;
      })}
      "
    </Container>
  );
}

const Container = styled.button`
  ${(props) => `
    border: none;
    background-color : white;
   ${props.theme.STYLES.AUTOCOMPLETE_TERMS}
   ${
     props.highlight
       ? `background-color:${props.theme.COLORS.AUTOCOMPLETE_ITEM_SELECT_BACKGROUND};`
       : ""
   }   
   `}
`;
// const Word = styled.div<{ highlight?: boolean }>`
//   ${(props) =>
//     props.highlight &&
//     `color: ${props.theme.COLORS.AUTOCOMPLETE_QUERY_HIGHLIGHT};`}
// `;

const HighlightWord = styled.span`
  ${(props) => `color: ${props.theme.COLORS.AUTOCOMPLETE_QUERY_HIGHLIGHT};`}
`;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      urlExtLink: actions.urlExtLink,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteText);
