import React, { useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { actions, connect } from "client-page";
import { MdClose } from "react-icons/md";

function IframeModal({ modalId, closeModal, title, src }) {
  const ref = useRef();
  const refIframe = useRef();

  useEffect(() => {
    // initiate the event handler
    const messageHandler = (ev) => {
      try {
        const data = JSON.parse(ev.data);
        if (data.type === "CLOSE_CHATBOT_IFRAME") {
          closeModal(modalId);
        }
      } catch (ex) {
        return;
      }
    };
    window.addEventListener("message", messageHandler);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("message", messageHandler);
    };
  });

  // useEffect(() => {
  //   if (ref && ref.current && refIframe && refIframe.current) {
  //     const iframeSizeHandler = () => {
  //       refIframe.current.style.visibility = "visible";
  //     };
  //     refIframe.current.addEventListener("load", iframeSizeHandler);
  //   }
  // }, [ref, refIframe]);

  function handleCloseClick() {
    closeModal(modalId);
  }
  return (
    <FloatingContainer>
      <Container ref={ref}>
        <Header>
          {title}
          <CloseContainer onClick={handleCloseClick}>
            <MdClose title="닫기" className="buttons" size="20" color="white" />
          </CloseContainer>
        </Header>
        <Body>
          <Iframe
            ref={refIframe}
            src={src}
            style={{ visibility: "hidden" }}
            onLoad={(e) => {
              e.target.style.visibility = "visible";
            }}
          />
        </Body>
      </Container>
    </FloatingContainer>
  );
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  ${(props) => props.theme.STYLES.MODAL_HEADER}
  border-radius:0;
`;
const Body = styled.div`
  ${(props) => props.theme.STYLES.MODAL_BODY}
  width: 100%;
  height: calc(100% - 35px);
  padding: 0;
  margin: 0;
  border-radius: 0;
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const CloseContainer = styled.button`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
  border: none;
`;

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      minimizeWindow: actions.minimizeWindow,
      closeModal: actions.closeModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(IframeModal);
