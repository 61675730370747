import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CUSTOM_PALETTE } from "../../../theme";
import api from "../../../server/rest/api";
import { toButton } from "../../../server/transform";

export function QuickMenu({ open, onClose }) {
  const [guideItems, setGuideItems] = useState([]);
  const firstFocusRef = useRef();
  useEffect(() => {
    if ( open && firstFocusRef.current){
      firstFocusRef.current.focus();
    }
  }, [open, guideItems]);


  useEffect(() => {
    api.counselQuickMenus().then((result) => {
      const newGuidItems = result.data.attachment.payload.elements.map(
        (element, idx) => {
          const btn = toButton(element);
          return {
            ...btn,
            run: () => {
              if (btn.run) btn.run();
              try {
                api.logQuickMenu({ index: idx, element });
              } catch (e) {}
            },
          };
        }
      );
      for (let i = newGuidItems.length; i < 6; i++) newGuidItems[i] = null;
      setGuideItems(newGuidItems);
    });
  }, []);

  function handleItemClick(index) {
    if (onClose) onClose();
    const run = guideItems[index]?.run;
    if (run) run();
  }

  return (
    <Container open={open}>
      {open&&
        <>
          <ButtonContainer>
            {guideItems.map((item, index) =>
              <Button ref={index===0?firstFocusRef:null} 
              title={item?.desc ? item.desc : 
                (item?.label?item?.label:undefined)}
              onClick={() => handleItemClick(index)}>
              { item ? (
                <>
                  <ItemImage src={item.icon} alt={item.label}></ItemImage>
                  <ButtonTitle
                    dangerouslySetInnerHTML={{
                      __html: item.label,
                    }}
                  />
                </>
              ) : (
                <></>
              ) }
            </Button>
            )}
          </ButtonContainer>
          <CloseButton onClick={onClose}>닫기</CloseButton>
        </>
      }
    </Container>
  );
}

const Container = styled.div`
  display:flex;
  flex-direction: column-reverse;
  transition: all 0.3s;
  position: relative;
  overflow: auto;
  overflow-y: hidden;
  z-index:1;
  height: 0;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  box-shadow: 5px 5px 20px 0px #0000001A;
  ${(props) => props.open && `height: 232px;`}
`;

const CloseButton = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background:${CUSTOM_PALETTE.GRAY_100};
  border-radius: 16px 16px 0px 0px;
  width:100%;
  height:32px;
  flex: 0 0 32px;
  border: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  border-bottom:none;
  line-height: 14px;
  font-size: 14px;
  font-weight: 700;
  color: ${CUSTOM_PALETTE.GRAY_800};
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction:row;
  flex-wrap:wrap;
`;

const Button = styled.button`
  border: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  border-bottom:none;
  border-left:none;
  width: 33.3333%;
  height:100px;
  background-color:white;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const ButtonTitle = styled.div`
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  font-weight:700;
  font-size:14px;
  line-height:14px;
  color:${CUSTOM_PALETTE.GRAY_800};
`;
const ItemImage = styled.img`
  height: 24px;
  margin-bottom:12px;
`;
