import React, { useState, useRef, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect, actions, utils } from "client-page";

import _ from "lodash";
import styled, { ThemeContext } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import { Checkable } from "client-page/dist/shared/types";
import { AiFillRead, AiFillQuestionCircle } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { GoGraph } from "react-icons/go";
import { MdSpellcheck } from "react-icons/md";
const { isBelowIE10, isIE } = utils;

interface GuideInputProp {
  data: any;
  popUserInput: any;
}

function GuideInput({ data, popUserInput }: GuideInputProp) {
  const theme = useContext<any>(ThemeContext);
  const TITLE = theme.TEXTS;
  const tmpIcon = [
    GoGraph,
    BsFillInfoCircleFill,
    AiFillRead,
    AiFillQuestionCircle,
    MdSpellcheck,
    BsFillInfoCircleFill,
  ];
  const tmpStyle = [
    `padding:8px;
    background-color:#A1E2D1;
    border-radius: 12px;
    `,
    `padding:8px;
    background-color:#36B9C1;
    border-radius: 12px;
    `,
    `padding:8px;
    background-color:#4997D1;
    border-radius: 12px;
    `,
    `padding:8px;
    background-color:#6083F9;
    border-radius: 12px;
    `,
    `padding:8px;
    background-color:#38CDCB;
    border-radius: 12px;
    `,
    `padding:8px;
    background-color:#00A7DE;
    border-radius: 12px;
    `,
  ];
  const [guideItems, setGuideItems] = useState<Checkable[]>(
    data.items.map((item: Checkable, idx: number) => ({
      ...item,
      comp: tmpIcon[idx],
      style: tmpStyle[idx],
    }))
  );

  function handleItemClick(index: number) {
    popUserInput();
    const run = guideItems[index].run;
    if (run) run();
    setGuideItems(
      guideItems.map((item, idx) => {
        if (index === idx) return { ...item, checked: true };
        else return { ...item, checked: false };
      })
    );
  }

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        popUserInput();
      }}
    >
      <Container>
        {/* <TitleContainer>
          <Line />
          <Title>{TITLE["input.guide.title"]}</Title>
          <Line />
        </TitleContainer> */}
        <SlideContainer itemCnt={guideItems.length}>
          {guideItems.map((item, index) => (
            <GuideItem
              key={index}
              item={item}
              onClick={() => handleItemClick(index)}
            />
          ))}
        </SlideContainer>
      </Container>
    </OutsideClickHandler>
  );
}

function GuideItem({ item, onClick }: { item: any; onClick: () => void }) {
  const ItemIcon = item.comp;
  return (
    <ItemContainer>
      <ItemPane checked={item.checked} onClick={onClick}>
        <ItemImageEx>
          <ItemImage myStyle={item.style} checked={item.checked}>
            {/* <Image src={item.icon} /> */}
            <ItemIcon color="white" size="35" />
          </ItemImage>
        </ItemImageEx>
        <ItemTitle>{item.label}</ItemTitle>
      </ItemPane>
    </ItemContainer>
  );
}

const Line = styled.div`
  border-top: 1px solid gray;
  margin-top: 8px;
  width: calc(50% - 100px);
  display: flex;
  flex-grow: 1;
  ${isBelowIE10() &&
  `
        display: inline-block;
    `}
`;
const Container = styled.div`
  ${(props) => props.theme.STYLES.INPUTBOX_CONTAINER}
  padding: 20px;
`;
const TitleContainer = styled.div`
  display: flex;
  height: 26px;
  -webkit-box-pack: center;
  justify-content: center;
  // margin-top: 20px;

  ${isBelowIE10() &&
  `
      display: block;
  `}
`;

const Title = styled.div`
  line-height: 16px;
  font-size: 11px;
  color: ${(props) => props.theme.COLORS.INPUT_TITLE_TEXT};
  text-align: center;
  width: 180px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  ${isBelowIE10() &&
  `
      display: inline-block;
  `}
`;
const SlideContainer = styled.div<{ itemCnt: number }>`
  ${isIE()
    ? `
    display:block;
    white-space:nowrap;
    `
    : `
    display: flex;
    flex-flow: row;
  `}
  overflow-x: auto;
`;
const ItemContainer = styled.div`
  width: 92px;
  text-align: center;
  ${isIE() &&
  `
    display:inline-block;
  `}
`;

const ItemPane = styled.div<{ checked?: boolean }>`
  & {
    width: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
  }
`;
const ItemImageEx = styled.div<{ myStyle?: string }>`
  margin: 1px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border: solid 1px #d7dbfd;
`;
const ItemImage = styled.div<{ myStyle?: string; checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  margin: 1px;
  border-radius: 29px;
  border: solid 2px white;
  overflow: hidden;
  background: linear-gradient(to bottom, #5e6ce2, #2a3bca);

  &:hover {
    background: linear-gradient(to bottom, #8793ff, #90a0ff);
  }
`;

const Image = styled.img.attrs<{ src?: string }>((props: any) => ({
  src: props.src,
}))`
  // TODO: Guide 이미지 새로 만들어진 것(50 x 50)으로 변경되면 주석 해제
  width: 50px;
  height: 50px;
`;
const ItemTitle = styled.div`
  width: 50px;
  margin-top: 5px;
  font-size: 11px;
  font-weight: bold;
  word-break: keep-all;
  color: gray;
`;

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      popUserInput: actions.popUserInput,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GuideInput);
