import {
  startCounsel,
  endCounsel,
  counselorWaitModalId,
  waitCounselor,
  stopWaitCounselor,
  cancelWaitCounselor,
  resetCounselState,
  setCounselId,
} from "../reducers/counselor";
import { setChannelId } from "../reducers/system";
import { setTrigger, startTrigger, transferSite } from "../reducers/server";
import { actions } from "client-page";
export default {
  ...actions,
  startCounsel,
  endCounsel,
  setCounselId,
  counselorWaitModalId,
  resetCounselState,
  waitCounselor,
  stopWaitCounselor,
  cancelWaitCounselor,
  setTrigger,
  startTrigger,
  transferSite,
  setChannelId,
};
