import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { connect, actions } from "client-page";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

function BigCarouselMessage({ message, setWidthOption }) {
  // useEffect(() => {
  //   setWidthOption("WIDE");
  // }, []);
  const items = message.data.items;
  const disabled = message.disabled;

  return (
    <Container>
      <MyCarousel
        infiniteLoop
        dynamicHeight
        showArrows={true}
        showStatus={false}
        centerMode={true}
        renderArrowPrev={ArrowPrev}
        renderArrowNext={ArrowNext}
        width={"100%"}
        centerSlidePercentage={100}
      >
        {items.map((item) => {
          return (
            <ItemContainer>
              <Item>
                {item.image && <Image src={item.image} />}
                <Describe>
                  {item.title && (
                    <DescribeTitle
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  )}
                  <DescribeText
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </Describe>
                <ButtonBox>
                  {item.buttons?.map((button) => (
                    <Button
                      disabled={disabled}
                      onClick={() => {
                        button.run();
                      }}
                    >
                      {button.label}
                    </Button>
                  ))}
                </ButtonBox>
              </Item>
            </ItemContainer>
          );
        })}
      </MyCarousel>
    </Container>
  );
}

const ArrowPrev = (clickHandler, hasPrev, label) => {
  return hasPrev ? (
    <ArrowContainer style={{ top: 0, left: 15 }} onClick={clickHandler}>
      <ArrowButton onClick={clickHandler}>
        <span>
          <BsChevronLeft size={20} />
        </span>
      </ArrowButton>
    </ArrowContainer>
  ) : (
    <></>
  );
};

const ArrowNext = (clickHandler, hasNext, label) => {
  return hasNext ? (
    <ArrowContainer style={{ top: 0, right: 15 }} onClick={clickHandler}>
      <ArrowButton onClick={clickHandler}>
        <span>
          <BsChevronRight size={20} />
        </span>
      </ArrowButton>
    </ArrowContainer>
  ) : (
    <></>
  );
};

const Container = styled.div`
  width: 100vw;
  margin-left: -57px;
`;
const ItemContainer = styled.div`
  padding: 0px 52px 32px;
`;
const Item = styled.div`
  width: 100%;
  position: relative;
  text-align: left;
  border-radius: 5px;
  word-break: break-all;
  padding-top: 12px;
  background-color: white;
  border: solid 1px #E0E0E0;
  border-radius: 8px;
  display:flex;
  flex-direction:column;
  align-items: center;
`;
const Image = styled.img`
  margin: 15px;
`;

const ButtonBox = styled.div`
  display:flex;
  flex-direction:column;
  padding: 0px 16px 8px 16px;
  width: 100%;
`;
const Button = styled.button`
  width: calc(100%);
  font-size:14px;
  font-weight:700;
  color: ${CUSTOM_PALETTE.GRAY_900};
  margin-bottom:8px;
  border-radius:8px;
  border: solid 1px ${CUSTOM_PALETTE.GRAY_300};
  background-color: ${CUSTOM_PALETTE.GRAY_100};
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
  :disabled {
    color: ${CUSTOM_PALETTE.GRAY_500};
  }
  line-height:20px;
  padding: 8px 16px;
`;

const Label = styled.div`
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: solid 2px #254c3a;
`;
const Describe = styled.div`
${(props) => props.theme.STYLES.MESSAGE_BALLOON_TEXT}
`;
const DescribeText = styled.div`
`;
const DescribeTitle = styled.div`
  font-size:16px;
  font-weight:bold;
`;

const MyCarousel = styled(Carousel)`

.carousel .slide {
  background: transparent;
}
.control-dots {
}
.carousel .control-dots .dot {
}
.carousel .thumbs-wrapper {
  margin: 20px;
  height: 0px;
}
.carousel img {
  width: auto;
  height: auto;
}
.carousel .slider-wrapper.axis-horizontal .slider {

}
.carousel .control-arrow {
}

.carousel .control-dots .dot {
  border-radius:4px;
  transition: all 0.5s;
  box-shadow:none;
  background:${CUSTOM_PALETTE.GRAY_400};
  margin: 0px 6px;
}
.carousel .control-dots .dot.selected{
  width:24px;
  background:${CUSTOM_PALETTE.PRIMARY_MAIN};
  box-shadow:none;
}

`;

const ArrowContainer = styled.div`
  width:32px;
  height:100%;
  position:absolute;
  z-index:2;
  display:flex;
  align-items:center;
  justify-content:center;
`;
const ArrowButton = styled.button`
  width:32px;
  height:32px;
  border-radius:16px;
  border: solid 1px ${CUSTOM_PALETTE.GRAY_800};
  color:${CUSTOM_PALETTE.GRAY_800};
  display:flex;
  align-items:center;
  justify-content:center;
  background-color:${DB_COLORS.DB_WHITE};
  & span{
    display:flex;
    align-items:center;
    justify-content:center;
  }
`;
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ postBack: actions.postBack }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BigCarouselMessage);
