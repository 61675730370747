import jquery from "jquery";
//import "jquery-ajax-transport-xdomainrequest";
import { setCookie } from "../cookie";
import { AuthUtils } from "../auth";

import { utils, constants } from "client-page";

const { extractCookieInfo, logger } = utils;
const TAG = "rest/index";

jquery.support.cors = true;

let channel_id = "";

export let cancelRequest;
export function setChannelId(id) {
  channel_id = id;
}

export function get(url, query) {
  logger.verbose(TAG, "get()");
  return jQueryAjax("GET", url, null, query);
}

export function post(url, body, query) {
  logger.verbose(TAG, "post()");
  return jQueryAjax("POST", url, body, query);
}

export function jQueryAjax(type, url, body, query) {
  logger.verbose(TAG, "jQueryAjax()");
  return new Promise((resolve, reject) => {
    let _url = url;
    // TODO
    // 정리 필요
    let _query = query;
    const sessionId = AuthUtils.getSessionId();

    if (sessionId) {
      _query = { ...query, CUSTOMER_SESSION_ID: `${sessionId}` };
    }

    if (_query) {
      _url += `?${jquery.param(_query)}`;
    }

    let _body = body ? body : {};
    // logger.debug('==========REQUEST==========');
    // logger.debug(_body);
    // logger.verbose(TAG, `_url: ${_url}`);

    const xhr = jquery.ajax({
      cache: false,
      url: _url,
      data: type === "GET" ? _body : JSON.stringify(_body),
      type: type,
      contentType: "application/json;charset=utf-8",
      dataType: "json",
      beforeSend: function (xhr) {
        xhr.setRequestHeader("channel_id", channel_id);
      },
      success: (result) => {
        // logger.debug(TAG, 'SUCCESS', result);
        resolve(result);
      },
      complete: function (request) {
        // const CookieInfo = request.getResponseHeader("Cookie-info");
        // const Extracted = extractCookieInfo(CookieInfo);
        // // logger.debug(TAG, 'Cookie-info:', CookieInfo, Extracted);
        // if (!!Extracted) {
        //   setCookie(
        //     CookieName.SESSION_ID,
        //     Extracted.customerSessionId,
        //     Extracted.maxAge
        //   );
        // }
      },
      error: (error) => {
        // logger.error(TAG, 'ERROR', error);
        reject(error);
      },
    });

    cancelRequest = () => xhr.abort();
  });
}

// /**
//  *
//  * @param {object} payload
//  * {
//  *   type: 'GET',
//  *   url: string(required),
//  *   token: string(required),
//  *   query: string(optional)
//  * }
//  */
// export function getAPI(payload) {
//   logger.verbose(TAG, "get() payload: ", payload);
//   if (!payload.hasOwnProperty("url")) {
//     logger.error(TAG, "URL 을 추가해주세요.");
//     return new Promise((resolve, reject) => reject());
//   }

//   return fetch({
//     type: "GET",
//     ...payload,
//   });
// }

// /**
//  *
//  * @param {object} payload
//  * {
//  *   type: 'POST',
//  *   url: string(required),
//  *   token: string(required),
//  *   body: object(optional),
//  *   query: string(optional)
//  * }
//  */
// export function postAPI(payload) {
//   logger.verbose(TAG, "post() payload: ", payload);
//   if (!payload.hasOwnProperty("url")) {
//     logger.error(TAG, "URL 을 추가해주세요.");
//     return new Promise((resolve, reject) => reject());
//   }

//   return fetch({
//     type: "POST",
//     ...payload,
//   });
// }

// export function fetch({ type, url, body, query }) {
//   logger.verbose(TAG, "jQueryAjax()");
//   return new Promise((resolve, reject) => {
//     let _url = url;
//     let _query = query;

//     const sessionId = AuthUtils.getSessionId();
//     if (sessionId) {
//       //   _url += `?CUSTOMER_SESSION_ID=${token}`;
//       _query["CUSTOMER_SESSION_ID"] = sessionId;
//     }

//     // if (token && query) {
//     //   _url += jquery.param(query);
//     // } else if (!token && query) {
//     //   _url += `?${jquery.param(query)}`;
//     // }

//     _url += `?${jquery.param(query)}`;

//     let _body = body ? body : {};
//     logger.debug("==========REQUEST==========");
//     logger.debug(_body);
//     logger.verbose(TAG, `_url: ${_url}`);

//     const xhr = jquery.ajax({
//       cache: false,
//       url: _url,
//       data: type === "GET" ? _body : JSON.stringify(_body),
//       type: type,
//       contentType: "application/json;charset=utf-8",
//       dataType: "json",
//       success: (result) => {
//         logger.debug(TAG, "SUCCESS", result);
//         resolve(result);
//       },
//       complete: function (request) {
//         // const CookieInfo = request.getResponseHeader("Cookie-info");
//         // const Extracted = extractCookieInfo(CookieInfo);
//         // logger.debug(TAG, "Cookie-info:", CookieInfo, Extracted);
//         // if (!!Extracted) {
//         //   setCookie(
//         //     CookieName.SESSION_ID,
//         //     Extracted.customerSessionId,
//         //     Extracted.maxAge
//         //   );
//         // }
//       },
//       error: (error) => {
//         logger.error(TAG, "ERROR", error);
//         reject(error);
//       },
//     });

//     cancelRequest = () => xhr.abort();
//   });
// }
