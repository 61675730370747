import {
  IReducerDef,
  GetRegisteredReducerDef,
  RegisterReducerDef,
  actions,
} from "client-page";
import { AnyAction } from "redux";
import { Action, createAction } from "redux-actions";
import { CookieName } from "../../shared/constants";
import { put, select, take, call } from "redux-saga/effects";
import { getCookie, setCookie } from "../../server/cookie";
import api from "../../server/rest/api";
import { setTrigger } from "./server";

export const setChannelId = createAction<string>("SET_CHANNEL_ID");
export const setParentHost = createAction<string>("SET_PARENT_HOST");

const orgReducerDef = GetRegisteredReducerDef("system") as IReducerDef<any>;
const reducerDef = {
  ...orgReducerDef,
  initState: {
    ...orgReducerDef.initState,
    channelId: "TM",
  },
  reducerMap: {
    ...orgReducerDef.reducerMap,

    SET_CHANNEL_ID: (state: any, action: Action<string>) => {
      return {
        ...state,
        channelId: action.payload,
      };
    },
    SET_PARENT_HOST: (state: any, action: Action<string>) => {
      return {
        ...state,
        parentHost: action.payload,
      };
    },
    SET_ENABLE_SITE_TRANSFER: (state: any, action: Action<boolean>) => {
      return {
        ...state,
        enableSiteTransfer: action.payload,
      };
    },
    SET_ENABLE_AUTO_SITE_TRANSFER: (state: any, action: Action<boolean>) => {
      return {
        ...state,
        enableAutoSiteTransfer: action.payload,
      };
    },
  },
  sagaMap: {
    ...orgReducerDef.sagaMap,
    INITIALIZE_PLUGIN: function* (action: any) {
      const config = action.payload;

      if (config.channelId) {
        yield put(setChannelId(config.channelId));
      }
      if (config.AutoTransfer) {
        yield put({ type: "SET_ENABLE_AUTO_SITE_TRANSFER", payload: true });
      }

      if (config.ParentHost) {
        yield put(setParentHost(config.ParentHost));
      }

      if (config.StartTriggerName) {
        yield put(setTrigger(config.StartTriggerName));
      }

      if (orgReducerDef.sagaMap && orgReducerDef.sagaMap.INITIALIZE_PLUGIN)
        yield call(orgReducerDef.sagaMap.INITIALIZE_PLUGIN, action);
    },
    START_APP: function* () {
      const state = (yield select()) as any;
      if (state.system.initApp) {
        return;
      }
      yield put(
        actions.startConnection({
          sessionId: state?.system?.pluginConfig?.CustomerSessionId,
        } as any)
      );
      yield take((action: AnyAction) => action.type === "SUCCESS_CONNECTION");
      yield put(actions.successStartApp());
      if (state.system.enableAutoSiteTransfer) {
        yield put({ type: "TRANSFER_SITE" });
      }
    },
  },
};

RegisterReducerDef(reducerDef);
// RegisterReducerDef("server", reducerDef);

// const reducerDef = {
//   ...orgReducerDef,
//   sagaMap: {
//     ...orgReducerDef.sagaMap,
//     START_CONNECTION: function* (action) {
//       console.log("start app: overloaded saga.");
//       if (true) yield call(orgReducerDef.sagaMap.START_CONNECTION, action);
//       else {
//       }
//       console.log("start app: overloaded saga end.");
//     },
//   },
// };
