import { get, post } from "./index";
import queryString from "query-string";
import { getAPIUrl } from "../url";
import { toAutoComplete, toMessage } from "../transform";
import { utils } from "client-page";

const { logger, getUUID } = utils;

const TAG = "rest/index";

export function isResultOK(result) {
  if (!result) {
    return false;
  }

  if (!result.status) {
    return false;
  }

  if (result.status.code === 0) {
    return true;
  }

  return false;
}
// function getChatChannelCodeQuery() {
//   const channel_id = getChatChannelCode();
//   if (channel_id === "H1") {
//     return `&ch=${channel_id}`;
//   }

//   return `&infoMobileAccessYn=${channel_id}`;
// }
export default {
  customerStart({ parentHost, event_code, referer_page }) {
    logger.verbose(TAG, "customerStart()");
    return new Promise((resolve, reject) => {
      get(`${getAPIUrl()}/customer/start`, {
        //channel_id,
        event_code,
        referer: document.referrer,
        referer_page,
        parent_host: parentHost,
      })
        .then((result) => {
          if (isResultOK(result)) {
            resolve({
              customerSessionId: result.data.customer_session_id,
              transferVisible: result.data.transfer_visible,
            });
          } else {
            reject("result is not ok");
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },

  customerMessageList({ count, order, last_id }) {
    return new Promise((resolve, reject) => {
      const body = {
        count,
        order,
        last_id,
      };
      logger.verbose(TAG, "customerMessageList()", body);
      post(`${getAPIUrl()}/customer/message/list`, body)
        .then((result) => {
          if (result.status && result.status.code === 0 && result.data) {
            let list = result.data.list;
            logger.debug(TAG, "list.length:", list.length);
            const res = list.map((item) => toMessage(item));
            resolve(res);
          } else {
            logger.error(TAG, "rest.customerMessageList().catch()", {});
            reject();
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },

  customerMessageSend(text, context) {
    return new Promise((resolve, reject) => {
      const body = {
        message: { text, button_context: context },
      };

      logger.verbose(TAG, "customerMessageSend()", body);
      post(`${getAPIUrl()}/customer/message/send`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve({
              lastMessage: toMessage(result.data.last_message),
              customerId: result.data.customer.customer_id,
            });
          } else {
            reject("invalid rest api result");
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },

  customerTrigger({ connection, triggerData }) {
    return new Promise((resolve, reject) => {
      const body = triggerData;
      logger.verbose(TAG, "customerTrigger()", body);
      let query = { online: connection };
      logger.debug(TAG, "query:", query);
      post(`${getAPIUrl()}/customer/trigger`, body, query)
        .then((result) => {
          resolve();
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },

  customerPostBack({ body, address }) {
    return new Promise((resolve, reject) => {
      logger.verbose(TAG, "customerPostBack()", body, address);
      post(`${getAPIUrl()}${address}`, body)
        .then((result) => resolve(result))
        .catch((reason) => reject(reason));
    });
  },
  counselHome() {
    return new Promise((resolve, reject) => {
      logger.verbose(TAG, "counselHome()");
      get(`${getAPIUrl()}/counsel/home`)
        .then((result) => {
          if (isResultOK(result)) {
            resolve({});
          } else reject({});
        })
        .catch((reason) => reject(reason));
    });
  },
  counselGreeting() {
    return new Promise((resolve, reject) => {
      logger.verbose(TAG, "counselGreeting()");
      get(`${getAPIUrl()}/counsel/greeting`)
        .then((result) => {
          if (isResultOK(result)) {
            // 배너 이미지 처리
            resolve(result.data);
          } else resolve({});
        })
        .catch((reason) => reject(reason));
    });
  },

  counselServiceCard() {
    return new Promise((resolve, reject) => {
      logger.verbose(TAG, "counselServiceCard()");
      get(`${getAPIUrl()}/customer/counsel/service/cards`)
        .then((result) => {
          if (isResultOK(result)) {
            resolve(result);
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerPolling() {
    return new Promise((resolve, reject) => {
      logger.verbose(TAG, "customerPolling()");
      get(`${getAPIUrl()}/customer/polling`)
        .then((result) => {
          if (result && isResultOK(result)) {
            const data = result.data.map((item) =>
              item.data.map((_item) => toMessage(_item))
            );
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((reason) => reject(reason));
    });
  },

  /**
   * 챗봇에게 소켓 응답으로 받고 싶은 메시지를 요청한다.
   * (요청하면 요청한 메시지 그대로 소켓 응답이 온다.)
   *
   * @param {*} body
   */
  customerMessageEcho(text) {
    return new Promise((resolve, reject) => {
      const body = {
        message: {
          text,
        },
      };
      logger.verbose(TAG, "customerMessageEcho()", body);
      post(`${getAPIUrl()}/customer/message/echo`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve({
              lastMessage: toMessage(result.data.last_message),
            });
          } else {
            reject();
          }
        })
        .catch((reason) => reject(reason));
    });
  },
  /**
   * 시나리오 발동
   * 시나리오 id 에 해당하는 시나리오를 발동한다.
   *
   * body example:
   * {
   *   scenario_id: 'AWlV6m5BsArYwGXg02CM'
   * }
   *
   * @param {object} body - scenario_id key 에 해당하는 값을 body 로 넘겨야 한다.
   */
  customerPostbackScenario(scenarioId) {
    logger.verbose(TAG, "customerPostbackScenario()", {});
    return new Promise((resolve, reject) => {
      let payload = {
        scenario_id: scenarioId || "",
      };
      post(`${getAPIUrl()}/customer/postback/scenario`, payload)
        .then((result) => resolve())
        .catch((reason) => reject(reason));
    });
  },

  customerAutoComplete(query) {
    logger.verbose(TAG, "customerAutoComplete()", query);
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/auto/complete`, {
        query,
      })
        .then((result) => {
          const list = result.data.list.map((item) => toAutoComplete(item));
          resolve(list);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerWaitCounselor(counsel_id) {
    const body = { counsel_id };
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/counsel/wait/status`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve({
              counselId: result.data.counsel_id,
              message: result.data.message,
              detail: result.data.detail,
              waiterCnt: result.data.count,
              waitingTime: result.data.wait_time,
              reservationUrl: result.data.reservation_url,
              over: result.data.type === "OVER",
            });
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerWaitCounselorCancel(counsel_id) {
    const body = { counsel_id };
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/counsel/wait/cancel`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve(result.data);
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerInputStart() {
    const query = {};
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/input/start`)
        .then((result) => {
          if (result.status.code === 0) {
            resolve();
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerInputEnd() {
    const query = {};
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/input/end`)
        .then((result) => {
          if (result.status.code === 0) {
            resolve();
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerMessageRead(message_id) {
    const body = {
      message_id,
    };
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/message/read`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve();
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  customerCounselStateCheck() {
    const query = {};
    return new Promise((resolve, reject) => {
      get(`${getAPIUrl()}/customer/counsel/state/check`, { query })
        .then((result) => {
          if (result.status.code === 0) {
            resolve(result);
            //result.data.state 0:Normal , 1:Busy, 2:Off
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },

  customerCounselStar({ star }) {
    const body = { star };
    return post(`${getAPIUrl()}/customer/counsel/star`, body);
  },
  customerCounselRequest() {
    const body = {};
    return get(`${getAPIUrl()}/customer/counsel/request`, body);
  },
  cusotomerURLCustomRequest({ type }) {
    const body = { type };
    return post(`${getAPIUrl()}/customer/url/custom/request`, body);
  },

  custormerUrlCustomOpen({ timeout }) {
    const body = { timeout };
    return new Promise((resolve, reject) => {
      post(`${getAPIUrl()}/customer/url/custom/open`, body)
        .then((result) => {
          if (result.status.code === 0) {
            resolve(result.data);
          } else reject(result);
        })
        .catch((reason) => reject(reason));
    });
  },
  logServiceCard({ index, element }) {
    const body = { index, element };
    return post(`${getAPIUrl()}/log/service_card`, body);
  },
  async customerTransfer() {
    try {
      const result = await get(`${getAPIUrl()}/customer/transfer`);
      if (result.status.code !== 0) {
        throw Error(result);
      }
      return result.data;
    } catch (e) {
      throw e;
    }
  },
  async customerCounselExcuseMessage({ setting }) {
    const body = { setting };
    try {
      const result = await post(
        `${getAPIUrl()}/customer/counsel/excuse/message`,
        body
      );
      if (result.status.code !== 0) {
        throw Error(result);
      }
      return result.data;
    } catch (e) {
      throw e;
    }
  },
  async customerCounselClose() {
    const body = {};
    try {
      const result = await get(`${getAPIUrl()}/customer/counsel/close`);
      if (result.status.code !== 0) {
        throw Error(result);
      }
      return result.data;
    } catch (e) {
      throw e;
    }
  },
  async kakaopaySendMessage({ channelId, cusotmerId }) {
    const body = { cusotmerId };
    try {
      const result = await post(
        `${getAPIUrl()}/kakaopay/sendMessage/${channelId}`,
        body
      );
      if (result.status.code !== 0) {
        throw Error(result);
      }
      return result.data;
    } catch (e) {
      throw e;
    }
  },
  edmsImageUpload(body) {
    return post(`${getAPIUrl()}/contract/edms/upload/image`, body);
  },
  counselQuickMenus() {
    return get(`${getAPIUrl()}/customer/counsel/quick_menus`, {});
  },
};
