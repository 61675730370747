import { GetRegisteredComponentMap, RegisterComponentMap } from "client-page";
import React from "react";
import BigCarouselMessage from "./BigCarouselMessage";
import GuideMessage from "./GuideMessage";
import ButtonMessage from "./ButtonMessage";

const MyComp = ({ text }) => {
  return <div>{text}</div>;
};

const orgCompMap = GetRegisteredComponentMap("MessageBalloon");
const compMap = {
  ...orgCompMap,
  bigCarousel: BigCarouselMessage,
  guide: GuideMessage,
  buttons: ButtonMessage,
  //text: MyComp,
};

RegisterComponentMap("MessageBalloon", compMap);
