import React, { useEffect, useState, useRef } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { connect, utils } from "client-page";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import actions from "../../redux/actions";
import api from "../../server/rest/api";
import { AppModeTypes } from "../../shared/modes";
import { openIframeModal } from "../../shared/utils/iframeUtil";
import { MdClose } from "react-icons/md";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { ReactComponent as Logo } from "./Logo.svg";

function CounselWaitModal({
  changeAppMode,
  closeModal,
  resetCounselState,
  //counselId,
  modalId,
  timeout,
  timeoutMessage,
  isApiUrlReset,
}) {
  const [data, setData] = useState(null);
  const [timeouted, setTimeouted] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [messageHtml, setMessageHtml] = useState("");
  const [detailHtml, setDetailHtml] = useState(undefined);
  const [timeoutMessageHtml, setTimeoutMessageHtml] = useState("");
  const [reservationUrl, setReservationUrl] = useState();
  const firstFocusRef = useRef();
  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, [firstFocusRef.current]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!timeout) return;
    const timeoutInterval = timeout - new Date().getTime();
    if (timeoutInterval > 0)
      setTimeout(() => {
        setTimeoutMessageHtml(utils.toContentText(timeoutMessage));
        setTimeouted(true);
      }, timeoutInterval);
    else {
      setTimeouted(true);
      setTimeoutMessageHtml(utils.toContentText(timeoutMessage));
    }
  }, [timeout, timeoutMessage]);

  async function fetchData() {
    setFetching(true);
    const result = await api.customerWaitCounselor();
    setData(result);
    setFetching(false);
    setMessageHtml(utils.toContentText(result.message));
    if (result.detail) setDetailHtml(utils.toContentText(result.detail));
    setReservationUrl(result.reservationUrl);
  }
  async function fetchCancel() {
    const counsel = await api.customerWaitCounselorCancel();
    if (isApiUrlReset) window.location.reload();
    //resetCounselState(counsel);
  }

  async function fetchCustomerUrlCustomOpen() {
    try {
      const data = await api.custormerUrlCustomOpen({ timeout });
      openIframeModal({
        src: data.url,
      });
    } catch (e) {}
  }
  async function handleClose() {
    fetchCancel();
    closeModal(modalId);
    changeAppMode({ type: AppModeTypes.DEFAULT });
  }

  if (!data) return null;
  const { message, waiterCnt = 0, waitingTime, over } = data;

  var seconds = moment.duration(waitingTime).seconds() || 0;
  var minutes = moment.duration(waitingTime).minutes();
  var hours = Math.trunc(moment.duration(waitingTime).asHours());

  var seconds = moment.duration(waitingTime).seconds() || 0;
  var minutes = moment.duration(waitingTime).minutes();
  var hours = Math.trunc(moment.duration(waitingTime).asHours());

  function displayTime() {
    let result = "";

    if (hours !== 0 && hours !== "0") {
      result += hours + "시간 ";
    }

    if (minutes !== 0 && minutes !== "0") {
      result += minutes + "분 ";
    }

    result += seconds + "초";

    return result;
  }

  return (
    <FloatingContainer>
      <Container>
        <Logo title="DB손해보험"style={{ padding: "12px 0px" }} />
        <CloseButton
          id="modal_close_btn"
          ref={firstFocusRef}
          title="닫기"
          onClick={handleClose}
          tabIndex={3}
        >
          <MdClose className="icons" title="닫기" size="20" />
        </CloseButton>
        {over ? (
          <>
            <Description
              dangerouslySetInnerHTML={{ __html: messageHtml }}
            ></Description>
            <Text2>
              대기인원 : {waiterCnt || 0}명<br />
              예상대기시간 : 1~2분
            </Text2>
            <LeftButton
             tabIndex={2}
              ref={firstFocusRef}
              onClick={() => {
                closeModal(modalId);
                changeAppMode({ type: AppModeTypes.DEFAULT });
              }}
            >
              돌아가기
            </LeftButton>
          </>
        ) : !timeouted ? (
          <>
            <Description
              dangerouslySetInnerHTML={{ __html: messageHtml }}
            ></Description>
            <Text dangerouslySetInnerHTML={{ __html: detailHtml }}></Text>
            <Text2>
              대기인원 : <span>{waiterCnt}명</span> <br />
              예상대기시간 : <span>1~2분</span>
            </Text2>
            <Bottom>
              <LeftButton
               tabIndex={2}
                ref={firstFocusRef}
                onClick={() => {
                  fetchData();
                }}
                disabled={fetching}
              >
                새로 고침
              </LeftButton>
              {reservationUrl ? (
                <Button
                tabIndex={2}
                  onClick={() => {
                    handleClose();
                    openIframeModal({
                      src: reservationUrl,
                    });
                  }}
                >
                  상담예약 신청
                </Button>
              ) : (
                <CancelButton
                tabIndex={2}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  상담 신청 취소
                </CancelButton>
              )}
            </Bottom>
          </>
        ) : (
          <>
            <Description
              dangerouslySetInnerHTML={{ __html: timeoutMessageHtml }}
            ></Description>
            <Bottom>
              <LeftButton
               tabIndex={2}
                onClick={() => {
                  handleClose();
                }}
              >
                취소
              </LeftButton>
              <Button
               tabIndex={2}
                ref={firstFocusRef}
                disabled={fetching}
                onClick={() => {
                  fetchCustomerUrlCustomOpen();
                  handleClose();
                }}
              >
                확인
              </Button>
            </Bottom>
          </>
        )}
      </Container>
    </FloatingContainer>
  );
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: calc(50% - 50px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing:border-box;
  width:320px;
  padding: 16px;
  border-radius: 16px;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  text-align:center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 34px;
  right: 16px;
  width:24px;
  height:24px;
  padding:2px;
  border-radius:4px;
  border: none;
  margin: 0px 0px 0px 12px;
  background: ${CUSTOM_PALETTE.GRAY_100};
  color:${CUSTOM_PALETTE.GRAY_600};
`;
const Description = styled.div`
  color:${CUSTOM_PALETTE.GRAY_900};
  font-size: 16px;
  line-height:24px;
  font-weight:700;
  padding: 12px 0px;
`;

const Bottom = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
`;
const LeftButton = styled.button`
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.GRAY_200};
  color:${CUSTOM_PALETTE.GRAY_800};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const Button = styled.button`
  margin-left:8px;    
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color: ${CUSTOM_PALETTE.PRIMARY_MAIN};
  color:${DB_COLORS.DB_WHITE};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;
const CancelButton = styled.button`
  margin-left:8px;    
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color: #F44336;
  color:${DB_COLORS.DB_WHITE};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;
const Text = styled.div`
  font-size: 14px;
  line-height:24px;
  color: ${CUSTOM_PALETTE.GRAY_900};
  `;

const Text2 = styled.div`
    font-size: 16px;
    line-height:24px;
    color: ${CUSTOM_PALETTE.GRAY_900};
    padding: 16px 0px 40px;
    >span{
      font-size: 16px;
      line-height:24px;
      font-weight:700;
      color: ${CUSTOM_PALETTE.PRIMARY_MAIN};
    }
    `;

const mapStateToProps = (state) => ({
  isApiUrlReset: state.server.isApiUrlReset,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: actions.closeModal,
      changeAppMode: actions.changeAppMode,
      resetCounselState: actions.resetCounselState,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CounselWaitModal);
