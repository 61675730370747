import io from "socket.io-client";
import EventEmitter from "events";
import event from "./event";
import { getWebSocketUrl } from "../url";
import { utils, env } from "client-page";
const TAG = "socket.io/index";

const { isBelowIE9, logger } = utils;

class SocketIOManager extends EventEmitter {
  constructor() {
    super();

    this._io = null;
    this._token = null;
    this._count = 0;
    this._timeout = 0;
  }

  open(token) {
    logger.verbose(TAG, "open()", token);

    if (this.isOpened()) {
      return;
    }

    if (!token) {
      return;
    }

    this._closeSocketIO();
    this._token = token;
    this._openSocketIO(token);
  }

  reopen() {
    logger.verbose(TAG, "reopen()");

    if (this.isOpened()) {
      return;
    }

    if (this._token) {
      this._closeSocketIO();
      this._openSocketIO(this._token);
    }
  }

  close() {
    logger.verbose(TAG, "close()");
    this._closeSocketIO();
  }

  getToken() {
    logger.verbose(TAG, "getToken()");

    return this._token;
  }

  isOpened() {
    let result = this._io !== null;
    logger.verbose(TAG, "isOpened()", result);
    return result;
  }

  send(data) {
    logger.verbose(TAG, "send()", data);
    this._sendDataBySocketIO(data);
  }

  _sendDataBySocketIO(data) {
    let stringData = JSON.stringify(data);
    logger.verbose(TAG, "_sendDataBySocketIO()", stringData);

    if (this._io && typeof this._io.send === "function") {
      logger.debug(TAG, "this._io.send()", "SUCCESS");
      this._io.send(stringData);
    }
  }

  _openSocketIO(token) {
    logger.verbose(TAG, "_openSocketIO()");

    if (token) {
      let io = this._createSocketIO(token);
      if (io) {
        this._setSocketIOCallback(io);
        this._setReconnectChecker(io);
        this._io = io;
      }
    }
  }

  _closeSocketIO() {
    logger.verbose(TAG, "_closeSocketIO()");

    if (this._io && typeof this._io.close === "function") {
      this._io.close();
      this._io = null;
    }
  }

  _createSocketIO(token) {
    logger.verbose(TAG, "_createSocketIO()");

    let urlStr = getWebSocketUrl(token);

    logger.verbose(TAG, "_createSocketIO()", `url: ${urlStr}`);
    let option = {
      transports: ["websocket", "polling"],
      timeout: 5000,
    };

    if (env.REACT_APP_WS_PATH) {
      option = {
        ...option,
        path: `${env.REACT_APP_WS_PATH}socket.io`,
      };
    }

    let SocketIO;

    if (isBelowIE9()) {
      SocketIO = io(urlStr);
    } else {
      SocketIO = io(urlStr, option);
    }

    return SocketIO;
  }

  _setSocketIOCallback(io) {
    logger.verbose(TAG, "_setSocketIOCallback()");

    io.on("connect", () => {
      logger.verbose(TAG, "on()", "connect");
      event.onconnect();
    });
    io.on("disconnect", () => {
      logger.verbose(TAG, "on()", "disconnect");
      event.ondisconnect();
      this._closeSocketIO();
    });
    io.on("error", (error) => {
      logger.verbose(TAG, "on()", "error", error);
      event.onerror();
      this._closeSocketIO();
    });
    io.on("message", (data) => {
      logger.verbose(TAG, "on()", "message", JSON.parse(data));
      let parsed = JSON.parse(data);
      if (parsed) {
        event.onmessage(parsed);
      }
    });
  }

  _setReconnectChecker(io) {
    logger.verbose(TAG, "_setReconnectChecker()");
    io.on("connect", this._initializeReconnect.bind(this));
    io.on("disconnect", this._tryReconnect.bind(this));
    io.on("error", this._tryReconnect.bind(this));
  }

  _initializeReconnect() {
    logger.verbose(TAG, "_initializeReconnect()");
    if (this._count !== 0) {
      this._count = 0;
    }
  }

  _tryReconnect() {
    //if (!this._io) return; //이 시점에 _io는 항상 null이다 (closeSocketIO 이후임)

    let milliseconds = (this._count * 2 + 1) * 1000;
    this._count += 1;
    logger.verbose(TAG, "_tryReconnect()", `milliseconds: ${milliseconds}`);
    this._timeout = setTimeout(() => {
      try {
        this.reopen();
      } catch (e) {
        console.log(e);
      }
    }, milliseconds);
  }
}

export default {
  manager: new SocketIOManager(),
};
