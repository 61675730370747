import {
  IReducerDef,
  GetRegisteredReducerDef,
  RegisterReducerDef,
  actions,
  sender,
  generators,
} from "client-page";
import { Action } from "redux-actions";
import { put, select, take, call } from "redux-saga/effects";

const orgReducerDef = GetRegisteredReducerDef("window") as IReducerDef<any>;
const reducerDef: IReducerDef<any> = {
  ...orgReducerDef,
  sagaMap: {
    ...orgReducerDef.sagaMap,
    RESTORE_MINIMIZE_WINDOW: function* (action: Action<any>) {
      const state = (yield select()) as any;

      if (state.server.isApiUrlReset)
        yield put({ type: "START_CONNECTION", payload: null });
      if (
        orgReducerDef.sagaMap &&
        orgReducerDef.sagaMap.RESTORE_MINIMIZE_WINDOW
      )
        yield call(orgReducerDef.sagaMap.RESTORE_MINIMIZE_WINDOW, action);
    },
  },
};

// const reducerDef = {
//   ...orgReducerDef,
//   sagaMap: {
//     ...orgReducerDef.sagaMap,
//     START_CONNECTION: function* (action) {
//       console.log("start app: overloaded saga.");
//       if (true) yield call(orgReducerDef.sagaMap.START_CONNECTION, action);
//       else {
//       }
//       console.log("start app: overloaded saga end.");
//     },
//   },
// };

RegisterReducerDef(reducerDef);
