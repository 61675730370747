import {
  IReducerDef,
  RegisterReducerDef,
  constants,
  utils,
  actions,
  types,
} from "client-page";

import { AnyAction } from "redux";
import {
  select,
  put,
  call,
  takeEvery,
  take,
  cancel,
  fork,
} from "redux-saga/effects";
import { createAction, Action } from "redux-actions";
import api from "../../server/rest/api";
import { AppModeTypes } from "../../shared/modes";
//import { setSiteKey } from "./server";

export const CounselState = {
  Chatbot: 0,
  Waiting: 1,
  Counseling: 2,
  Finish: 3,
  Check: 4,
  // state
  // 0, 상담사 없음. 챗봇
  // 1, 상담 대기 상태
  // 2. 상담중
  // 3, 상담완료.
  // 4. 상담확인
};

const { pushModal, closeModal } = actions;
const {} = utils;

const {} = constants;
const { getUUID } = utils;
type AnyMessage = types.AnyMessage;
const TAG = "redux/reducer/counselor";

interface CounselorState {
  on: boolean;
  wait: boolean;
  counselId: number | null;
  waitModalId: string | null;
}
interface StartCounselProp {
  counselId: number;
}
interface EndCounselProp {
  counselId: number;
}

export const startCounsel = createAction<StartCounselProp>("START_COUNSEL");
export const endCounsel = createAction<EndCounselProp>("END_COUNSEL");
export const setCounselId = createAction<number | null>("SET_COUNSEL_ID");
export const waitCounselor = createAction<{ counselId: string }>(
  "WAIT_COUNSELOR"
);
export const cancelWaitCounselor = createAction<{ counselId: string }>(
  "CANCEL_WAIT_COUNSELOR"
);
export const stopWaitCounselor = createAction<{ counselId: string }>(
  "STOP_WAIT_COUNSELOR"
);
export const counselorWaitModalId = createAction<string | null>(
  "SET_COUNSELOR_WAIT_MODAL_ID"
);
export const resetCounselState = createAction<any>("RESET_COUNSEL_STATE");
const reducerDef: IReducerDef<CounselorState> = {
  path: "counselor",
  initState: {
    on: false,
    wait: false,
    counselId: null,
    waitModalId: null,
  },

  reducerMap: {
    START_WAIT_COUNSELOR: (state, action) => {
      return { ...state, wait: true };
    },
    STOP_WAIT_COUNSELOR: (state, action) => {
      return { ...state, wait: false };
    },
    SET_COUNSEL_ID: (state, action: Action<number | null>) => {
      return { ...state, counselId: action.payload };
    },
    SET_COUNSELOR_WAIT_MODAL_ID: (state, action: Action<string | null>) => {
      return { ...state, waitModalId: action.payload };
    },
  },
  sagaMap: {
    START_COUNSEL: function* (action: Action<StartCounselProp>) {},
    END_COUNSEL: function* (action: Action<EndCounselProp>) {},
    RESET_COUNSEL_STATE: function* (action: Action<any>) {
      const counsel = action.payload;
      // const customer_id = counsel.customer?.site_key;
      // if (customer_id) {
      //   yield put(setSiteKey(customer_id));
      // }
      yield put(actions.changeAppMode({ type: AppModeTypes.DEFAULT }));
      if (!counsel) return;
      if (counsel.in_waiting_queue) {
        yield put(
          actions.changeAppMode({
            type: AppModeTypes.COUNSEL_WAIT,
            props: {
              counselId: counsel.id,
            },
          })
        );
      } else if (
        counsel.state === CounselState.Waiting ||
        counsel.state === CounselState.Counseling
      ) {
        yield put(
          actions.changeAppMode({
            type: AppModeTypes.COUNSEL,
            props: {
              counselId: counsel.id,
            },
          })
        );
      }
    },
  },
};

// const reducerDef = {
//   ...orgReducerDef,
//   sagaMap: {
//     ...orgReducerDef.sagaMap,
//     START_CONNECTION: function* (action) {
//       console.log("start app: overloaded saga.");
//       if (true) yield call(orgReducerDef.sagaMap.START_CONNECTION, action);
//       else {
//       }
//       console.log("start app: overloaded saga end.");
//     },
//   },
// };

RegisterReducerDef(reducerDef);
