import { actions, getStore } from "client-page";

export function openIframeModal({ src, title }) {
  const store = getStore();
  const state = store.getState();
  const { parentHost } = state.system;
  //if (parentHost && button.data.indexOf(state.system.parentHost) === -1) {
  if (
    //parentHost &&
    // parentHost.indexOf("directdb.co.kr") === -1 &&
    // parentHost.indexOf("tscb.dbins.net") === -1 && 
    state?.system?.pluginConfig?.IframeTest !== true
  ) {
    window.open(
      src + "&isPop=1",
      "uri_cusotm_from_chatbot",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=400, height=740, left=50"
    );
  } else {
    store.dispatch(
      actions.pushModal({
        modalType: "iframe",
        modalProps: {
          title,
          src,
        },
      })
    );
  }
}
