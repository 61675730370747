import React, { useMemo, useState, useRef, useEffect } from "react";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { actions, connect } from "client-page";
import { AppModeTypes } from "../../shared/modes";
import api from "../../server/rest/api";
import { CUSTOM_PALETTE, DB_COLORS } from "../../theme";
import { ReactComponent as Logo } from "./Logo.svg";

function CloseWindowModal({
  modalId,
  modeType,
  channelId,
  minimizeWindow = () => {},
  closeModal = () => {},
  sendMessage = () => {},
}) {
  const kp = useMemo(() => (channelId ? channelId.indexOf("KP") >= 0 : false), [
    channelId,
  ]);
  const [bCounsel, setCounsel] = useState(modeType === AppModeTypes.COUNSEL);
  const firstFocusRef = useRef();
  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, [firstFocusRef.current]);
  function handlCancel(rating) {
    closeModal(modalId);
  }

  async function handleSubmit() {
    if (kp) {
      if (window.parent?.PayWebCore?.exec) {
        //kakao pay 웹뷰 종료
        window.parent.PayWebCore.exec({
          command: "close",
        });
      }
    } else {
      if (bCounsel) {
        //sendMessage({ message: "상담 종료 신청" });
        api.customerCounselClose();
      }
      minimizeWindow();
    }
    closeModal(modalId);
  }

  return (
    <FloatingContainer>
      <Container>
        <Logo title="DB손해보험"style={{ paddingTop: 12 }} />
        <Description>
          {kp ? "상담창을 닫겠습니까?" : "상담을 종료하시겠습니까?"}
        </Description>
        <Bottom>
          <LeftButton ref={firstFocusRef} onClick={handleSubmit}>
            확인
          </LeftButton>
          <Button onClick={handlCancel}>취소</Button>
        </Bottom>
      </Container>
    </FloatingContainer>
  );
}

const FloatingContainer = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

const Container = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: calc(50% - 50px);
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  box-sizing:border-box;
  width:320px;
  height: 200px;
  padding: 16px;
  border-radius: 16px;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: space-between;
  text-align:center;
`;

const Description = styled.div`
  color:${CUSTOM_PALETTE.GRAY_900};
  font-size: 16px;
  line-height:24px;
  font-weight:700;
`;

const Bottom = styled.div`
  display:flex;
  flex-direction: row;
  width: 100%;
`;
const LeftButton = styled.button`
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.GRAY_200};
  color:${CUSTOM_PALETTE.GRAY_800};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const Button = styled.button`
  margin-left:8px;
  flex: 0 0 calc(50% - 4px);
  height:48px;
  border: none;
  border-radius:8px;
  display:flex;
  align-items:center;
  justify-content: center;
  background-color:${CUSTOM_PALETTE.PRIMARY_MAIN};
  color:${DB_COLORS.DB_WHITE};
  font-size: 14px;
  line-height:24px;
  font-weight:700;
  transition: all 0.3s;
  :active {
    background-color:${CUSTOM_PALETTE.GRAY_300};
  }
`;

const mapStateToProps = (state) => ({
  modeType: state.system.mode.type,
  channelId: state.system.channelId,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      minimizeWindow: actions.minimizeWindow,
      closeModal: actions.closeModal,
      sendMessage: actions.sendMessage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseWindowModal);
