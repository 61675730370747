import {
  types,
  constants,
  actions,
  getStore,
  sender,
  utils,
} from "client-page";
import { ButtonMessageData } from "client-page/dist/shared/types";
import { makeMessageUri } from "./url";
import api from "./rest/api";
import { openIframeModal } from "../shared/utils/iframeUtil";

const { RoleType } = constants;
const { sendMessage, postBack, runSharpSearch, pushUserInput } = actions;
type AnyMessage = types.AnyMessage;
type Sender = types.Sender;
type GuideMessageData = types.GuideMessageData;
type TextMessageData = types.TextMessageData;
type AccordionMessageData = types.AccordionMessageData;

interface ServiceCardPayload {
  template_type: string;
  elements: {
    type: string;
    icon: string;
    text: string;
    label: string;
    data?: any;
  }[];
}
interface CarouselPayload {
  template_type: string;
  elements: {
    attachment: any;
    text: string;
    title: string;
    image_uri: string;
    data?: any;
  }[];
}

interface ServerSender {
  role_type?: number;
  nick: string;
  user_name: string;
  user_id: number;
}
interface ServerMessage {
  id: string;
  date: number;
  channel_code: string;
  sender_type: number;
  sender: ServerSender;
  message: {
    attachment?: any;
    auto_close?: boolean;
    faq_id?: string;
    image_uri?: string;
    is_auto_close?: boolean;
    media_uri?: string;
    scenario_card_id?: string;
    scenario_id?: string;
    text?: string;
    title?: string;
  };
}

function toContentText(text: string | undefined): string {
  return utils
    .toContentText(text)
    .replace(/(\d{2,3}-?\d{3,4}-?\d{4})|(\d{4}-\d{4})/g, (phoneNumber) => {
      return '<a href="tel:' + phoneNumber + '">' + phoneNumber + "</a>";
    });
}

export function toButton(button: {
  type: string;
  data?: any;
  icon: string;
  label: string;
  label2?: string;
  postback?: any;
  context?: any;
}) {
  var run: undefined | (() => void) = undefined;
  const store = getStore();
  let state = null;
  let outerLink = false;
  switch (button.type) {
    case "uri":
    case "call":
      run = () => {
        store.dispatch(actions.urlExtLink(makeMessageUri(button.data)));
      };
      outerLink = true;
      break;
    case "uri_href":
      run = () => {
        try {
          const from = encodeURIComponent(window.parent.location.href);
          const uri = new URL(button.data);
          uri.searchParams.set("from", from);
          store.dispatch(
            actions.urlExtLinkOpt(makeMessageUri(uri.href), "href")
          );
        } catch (e) {
          console.log(e);
        }
      };
      outerLink = true;
      break;
    case "uri_custom":
      run = () => {
        window.open(
          button.data,
          "uri_cusotm_from_chatbot",
          "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=450, height=700"
        );
        store.dispatch(
          actions.urlExtLinkOpt(makeMessageUri(button.data), "uri_custom")
        );
      };
      outerLink = true;
      break;

    case "uri_iframe":
      run = () =>
        openIframeModal({
          title: button.label,
          src: button.data,
        });
      break;

    case "counsel":
    case "text":
      run = () =>
        store.dispatch(
          sendMessage({ message: button.data, context: button.context })
        );
      break;
    case "postback":
      run = () =>
        store.dispatch(
          postBack({ address: button.data, body: button.postback })
        );
      break;
    case "postback2":
      run = () =>
        store.dispatch(
          postBack({ address: button.data.url, body: button.data })
        );
      break;
    case "terms_explain":
      run = () => store.dispatch(runSharpSearch());
      break;
    case "edms_image_upload":
      run = () =>
        store.dispatch(
          actions.pushModal({
            modalType: "edmsImageUpload",
            modalProps: {
              data: button.data,
            },
          })
        );
      break;
    default:
  }

  return {
    run,
    label: button.label,
    desc: outerLink ? "새 창 열림" : button.label2,
    icon: makeMessageUri(button.icon),
  };
}
export function toMessage(_item: ServerMessage): AnyMessage | null {
  const store = getStore();
  const state = store.getState();
  const channel_code = state.system.channelId;
  if (channel_code && _item.channel_code && channel_code !== _item.channel_code)
    return null;

  //console.log("toMessage", _item);
  const { attachment } = _item.message;

  let senderId = "";
  if (_item.sender.role_type === 0) {
    senderId = sender.SenderID.CHATBOT;
  } else if (_item.sender.role_type === 1) {
    senderId = sender.SenderID.DEFAULT_COUNSELOR;
  } else {
    senderId = sender.SenderID.USER;
  }

  const commons = {
    senderId,
    id: _item.id,
    date: _item.date,
  };

  let type = !attachment ? "text" : attachment.payload.template_type;
  if (type === "text") {
    const data: TextMessageData = {
      text: _item.message.text ? toContentText(_item.message.text) : "",
      image: makeMessageUri(_item.message.image_uri),
    };
    return {
      ...commons,
      type: "text",
      data,
    };
  } else if (type === "multi_select" || type === "select") {
  } else if (type === "carousel") {
    const payload = attachment.payload as CarouselPayload;
    const elements = payload.elements;
    const data = {
      items: elements.map((element, idx) => {

        const buttonElements = element.attachment?.payload?.elements;
        const buttons = buttonElements?.map((element: any) => toButton(element));
        return {
          title: element.title ? toContentText(element.title) : "",
          text: element.text ? toContentText(element.text) : "",
          image: element.image_uri,
          buttons,
        };
      }),
    };
    return {
      ...commons,
      type: "bigCarousel",
      data,
    };
  } else if (type === "service_card") {
    const payload = attachment.payload as ServiceCardPayload;
    const elements = payload.elements;

    const tmpImg = [
      "./images/service_card/income_related.svg",
      "./images/service_card/national_scholarship.svg",
      "./images/service_card/student_loan.svg",
      "./images/service_card/support_range.svg",
      "./images/service_card/repayment.svg",
      "./images/service_card/faq.svg",
    ];
    const data: GuideMessageData = {
      items: elements.map((element, idx) => {
        const btn = toButton(element);
        return {
          ...btn,
          icon: btn.icon ? btn.icon : tmpImg[idx],
          run: () => {
            if (btn.run) btn.run();
            try {
              api.logServiceCard({ index: idx, element });
            } catch (e) { }
          },
          style: `
          background-color:#009559;
          border-radius: 12px;
          width:70px;
          height:70px;
          border:none;
          `,
        };
      }),
    };
    return {
      ...commons,
      type: "guide",
      data,
    };
  } else if (attachment.payload.template_type === "product_group") {
    let productGroup = attachment.payload.elements;
  } else if (attachment.payload.template_type === "product") {
    let product = _item;
  } else if (type === "faq_accordion") {
    const payload = attachment.payload;
    const elements = payload.elements;

    const data: AccordionMessageData = {
      title: _item.message.text ? _item.message.text : "",
      menu: elements.map((element: any) => {
        const label = element.text || element.title;
        let menu = [];
        if (element.attachment && element.attachment.payload) {
          const elements = element.attachment.payload.elements;
          menu = elements.map((element: any) => {
            return toButton(element);
          });
        }
        return {
          label,
          menu,
        };
      }),
    };
    return {
      ...commons,
      type: "accordion",
      data,
    };
  } else if (type === "button" || type === "buttons") {
    const payload = attachment.payload;
    const elements = payload.elements;

    const data: ButtonMessageData = {
      text: _item.message.text ? toContentText(_item.message.text) : "",
      image: makeMessageUri(_item.message.image_uri),
      buttons: elements.map((button: any, index: number) => {
        return toButton(button);
      }),
    };
    return {
      ...commons,
      type: "buttons",
      data,
    };
  } else if (type === "faq_popup") {
    const payload = attachment.payload;
    const elements = payload.elements;

    const data: ButtonMessageData = {
      text: _item.message.text ? toContentText(_item.message.text) : "",
      image: makeMessageUri(_item.message.image_uri),

      buttons: elements.map((button: any, index: number) => {
        return toButton(button);
      }),
    };
    return {
      ...commons,
      type: "buttonsPopup",
      data,
    };
  }

  console.error("AnyMessage Transform was Failed");
  return null;
}

interface ServerAutoComplete {
  display?: string;
  keyword?: string;
  graph?: {
    period: string;
    value: string;
  }[];
}

interface AutoComplete {
  type: string;
  text: string;
  textInput: string;
  graph: any;
}

export function toAutoComplete(_item: ServerAutoComplete): AutoComplete | null {
  let { display, graph, keyword } = _item;
  if (!display || !keyword) return null;

  return {
    type: "text",
    text: display,
    textInput: keyword,
    graph: graph,
  };
}
