import { utils, modes, generators } from "client-page";
import { ModalInfo } from "client-page/dist/shared/types";
import {
  put,
  call,
  select,
  takeEvery,
  take,
  cancel,
  fork,
} from "redux-saga/effects";
import { AnyAction } from "redux";
import api from "../../server/rest/api";
import actions from "../../redux/actions";

export const AppModeTypes = {
  ...modes.AppModeTypes,
  COUNSEL: "COUNSEL",
  COUNSEL_WAIT: "COUNSEL_WAIT",
};

const { RegisterAppMode } = modes;

const { getUUID } = utils;
RegisterAppMode({
  type: AppModeTypes.DEFAULT,
  onStart: function* () {
    yield put(actions.setFields(["MessageList", "Input"]));
    yield put(actions.setUserInput({ type: "Text" }));
    yield put(actions.setChatbotOn(true));
  },
  onEnd: function* () {
    //yield call(disableAllMessageButtons);
    //yield put(actions.setChatbotOn(false));
  },
});
RegisterAppMode({
  type: AppModeTypes.COUNSEL,
  starter: ({ counselId }) => ({
    onStart: function* () {
      yield put(actions.setFields(["MessageList", "Input"]));
      yield put(actions.setUserInput({ type: "Text" }));
      yield put(actions.setCounselId(counselId));
      yield put(actions.disableAllMessages());
    },
    onEnd: function* () {
      const state = (yield select()) as any;
      const counselId = state.counselor.counselId;
      yield put(actions.setCounselId(null));
    },
  }),
});
RegisterAppMode({
  type: AppModeTypes.COUNSEL_WAIT,
  starter: ({ timeout, timeoutMessage }) => ({
    onStart: function* () {
      const modalId = getUUID();
      yield put(actions.counselorWaitModalId(modalId));
      yield put(
        actions.pushModal({
          modalId,
          modalType: "counselWait",
          modalProps: {
            timeout,
            timeoutMessage,
          },
        })
      );
      // const sessionTimeout = localStorage.getItem(
      //   "CHATBOT_COUNSEL_WAIT_TIMEOUT"
      // );
      // let timeoutInfo = sessionTimeout ? JSON.parse(sessionTimeout) : null;

      // const currentTime = new Date().getTime();
      // const resetTimeout = timeoutInfo
      //   ? timeoutInfo.startTime + timeoutInfo.timeout < currentTime
      //   : true;
      // if (resetTimeout && timeout && timeoutMessage) {
      //   // 타임아웃 시간 리셋을 원할 때만
      //   timeoutInfo = {
      //     timeout,
      //     timeoutMessage,
      //   };
      //   localStorage.setItem(
      //     "CHATBOT_COUNSEL_WAIT_TIMEOUT",
      //     JSON.stringify(timeoutInfo)
      //   );
      // }

      //if (timeoutInfo) {
      //   yield put(
      //     actions.pushModal({
      //       modalId,
      //       modalType: "counselWait",
      //       modalProps: {
      //         ...timeoutInfo, //{ timeout, timeoutMessage, startTime }
      //       },
      //     })
      //   );
      // }
    },
    onEnd: function* () {
      const state = (yield select()) as any;
      const waitModalId = state.counselor.waitModalId;
      yield put(actions.closeModal(waitModalId));
    },
  }),
});
