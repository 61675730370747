import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import { bindActionCreators } from "redux";
import _ from "lodash";
import styled, { ThemeContext } from "styled-components";

import { actions, utils, constants, elements, connect } from "client-page";
import { AppModeTypes } from "../../../shared/modes";
import { CUSTOM_PALETTE, DB_COLORS } from "../../../theme";
import { ReactComponent as CardIcon } from "./icons/CardIcon.svg";
import { ReactComponent as SendIcon } from "./icons/SendIcon.svg";
import { QuickMenu } from "./QuickMenu";

const _isMobile = utils.isMobile();
const _isSSbrowser = utils.isSSbrowser();
const { KeyCode } = constants;
const { AutoComplete } = elements.userinput;

interface TextInputProp {
  mode: { type: string };
  autoCompleteList: any[];
  autoCompleteSelected: number | undefined;
  isApiUrlReset: boolean;
  channelId: string;
  startInput: (payload: void) => void;
  endInput: (payload: void) => void;
  goBackHome: (payload: void) => void;
  sendMessage: (arg: { message: string }) => void;
  sendAutoCompleteQuery: (query: string) => void;
  setAutoCompleteSelected: (idx: number | undefined) => void;
  pushModal: (props: { modalType: string }) => void;
}

const debounce500 = _.debounce(
  (func: (arg: string) => void, messageText: string) => {
    func(messageText);
  },
  500
);

function TextInput({
  mode,
  autoCompleteList,
  autoCompleteSelected,
  isApiUrlReset,
  channelId,
  startInput,
  endInput,
  goBackHome,
  sendMessage,
  sendAutoCompleteQuery,
  setAutoCompleteSelected,
  pushModal,
}: TextInputProp) {
  const [isDefaultMode, setIsDefaultMode] = useState(
    mode.type === AppModeTypes.DEFAULT && !isApiUrlReset
  );
  useEffect(() => {
    setIsDefaultMode(mode.type === AppModeTypes.DEFAULT && !isApiUrlReset);
  }, [mode, isApiUrlReset]);
  const textInput = useRef<any>();
  const [messageText, __setMessageText] = useState<string>("");
  const theme = useContext<any>(ThemeContext);
  const [acSelect, SetACSelect] = useState<number>(-1);
  const [openQuickMenu, setOpenQuickMenu] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const isCounsel = useMemo(() => mode.type === AppModeTypes.COUNSEL, [mode.type]);
  const kp = useMemo(() => (channelId ? channelId.indexOf("KP") >= 0 : false), [
    channelId,
  ]);
  const homeDisable = useMemo(() => !isDefaultMode || kp, [isDefaultMode, kp]);
  const homeHide = useMemo(() => kp, [kp]);
  const writeDisable = useMemo(() => (kp && (!isCounsel)), [kp, isCounsel]);


  const handleCloseQuickMenu = useCallback(() => {
    setOpenQuickMenu(false);
    const qbtn = document.getElementById('quickmenu_btn');
    qbtn?.focus();
  }, [])

  function setMessageText(value: string) {
    if (messageText === "" && value !== "") startInput();
    if (messageText !== "" && value === "") endInput();
    __setMessageText(value);
  }
  useEffect(() => {
    debounce500(sendAutoCompleteQuery, messageText);
  }, [messageText]);

  if (textInput.current) {
    if (messageText.length > 0) {
      textInput.current.style.height = textInput.current.style.minHeight;
      textInput.current.style.height = textInput.current.scrollHeight + "px";
    } else {
      textInput.current.style.height = textInput.current.style.minHeight;
    }
  }

  const isTyping = messageText !== "";

  const TITLE = theme.TEXTS;

  function _checkTyping(previous: string, current: string) {
    // logger.verbose(TAG, '_checkTyping()', previous, current)
    if (previous === "" && current !== "") {
      onTypingStart();
    } else if (previous !== "" && current === "") {
      onTypingEnd();
    }
  }
  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const messageTextValue = event.target.value;
    _checkTyping(messageText, messageTextValue);

    const changeMessageText = utils.blockingTag(messageTextValue);
    const changeValue = utils.checkByte(changeMessageText);
    setMessageText(changeValue);
  }
  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setIsFocus(true);
    },
    []
  );

  const handleBlur = useCallback((e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsFocus(false);
  }, []);

  function onTypingStart() { }

  function onTypingEnd() { }
  function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    // if (_isMobile) {
    //   if (event.keyCode === KeyCode.ENTER) {
    //     event.keyCode = 0;
    //     return;
    //   }
    // }

    if (event.keyCode === KeyCode.ENTER) {
      if (event.shiftKey) {
        return;
      }
      if (
        autoCompleteSelected !== undefined &&
        autoCompleteSelected < autoCompleteList.length
      ) {
        const currAC = autoCompleteList[autoCompleteSelected];
        SendAndFocus(currAC.text);
      } else {
        SendAndFocus(messageText);
      }
      event.preventDefault();
      return;
    }

    if (event.keyCode === KeyCode.UP && autoCompleteList.length > 0) {
      if (autoCompleteSelected === undefined || autoCompleteSelected === 0)
        setAutoCompleteSelected(autoCompleteList.length - 1);
      else setAutoCompleteSelected(autoCompleteSelected - 1);
      event.preventDefault();
    }
    if (event.keyCode === KeyCode.DOWN && autoCompleteList.length > 0) {
      if (
        autoCompleteSelected === undefined ||
        autoCompleteSelected >= autoCompleteList.length - 1
      )
        setAutoCompleteSelected(0);
      else setAutoCompleteSelected(autoCompleteSelected + 1);
      event.preventDefault();
    }
  }

  function onClick() {
    SendAndFocus(messageText);
  }

  function SendAndFocus(message: string) {
    // 공백 문자열만 입력한 뒤 보내기 버튼을 누르면 messageText 를 초기화한다.
    if (message.trim() === "") {
      setMessageText("");
      return;
    }
    if (!utils.isApple() && textInput.current) {
      textInput.current.focus();
    }

    setMessageText("");
    sendMessage({ message });
  }
  function handleHomeButtonClick() {
    setOpenQuickMenu((open) => !open);
    // if (isDefaultMode) {
    //   goBackHome();
    // }
  }

  return (
    <Fragment>
      <AutoComplete
        onConfirm={(changeValue: string) => {
          setMessageText(changeValue);
        }}
      />
      <QuickMenu open={openQuickMenu} onClose={handleCloseQuickMenu} />
      <Container>
        {
          <HomeButton
            id='quickmenu_btn'
            title={"퀵메뉴"}
            open={openQuickMenu}
            onClick={handleHomeButtonClick}
            disabled={!isDefaultMode}
          >
            <CardIcon title={"퀵메뉴"} />
          </HomeButton>
        }
        <InputContainer focus={isFocus}>
          <Write
            disabled={writeDisable}
            title={TITLE["center.bottom.textarea.placeholderText"]}
            placeholder={writeDisable ? TITLE["center.bottom.textarea.placeholderDisabledText"] : TITLE["center.bottom.textarea.placeholderText"]}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            value={messageText}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={textInput}
          />
          <SendButton disable={!isTyping} onClick={onClick}
            title={TITLE["center.bottom.button.send"]}>
            <SendIcon
              color="white"
              title={TITLE["center.bottom.button.send"]}
            />
          </SendButton>
        </InputContainer>
      </Container>
      <FakeCloseButton
        title="닫기"
        onClick={() => {
          pushModal({ modalType: "closeWindow" });
        }}
      />
    </Fragment>
  );
}

const Container = styled.div`
  position: relative;
  z-index:2;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  ${(props) => props.theme.STYLES.INPUTBOX_CONTAINER}
`;

const HomeButton = styled.button<{ open: boolean }>`
  width: 24px;
  height: 24px;
  margin: 8px 8px 10px;
  border: none;
  padding: 0;
  transition: all 0.3s;
  cursor: pointer;
  background-color: ${DB_COLORS.DB_WHITE};
  ${(props) =>
    props.disabled
      ? `color:  #DCDCDC;`
      : props.open
        ? `
        color: ${CUSTOM_PALETTE.GRAY_700};
        `
        : `
        color:  ${CUSTOM_PALETTE.PRIMARY_DARK};
        &:hover {
          color:  ${CUSTOM_PALETTE.GRAY_700};
        }
        `}
`;

const InputContainer = styled.div<{ focus: boolean }>`
  display:flex;
  flex-grow:1;
  align-items:flex-end;
  border-radius:20px;
  box-sizing: border-box;
  border: solid 2px transparent;
  background-color: ${CUSTOM_PALETTE.GRAY_100};

  ${(props) =>
    props.focus &&
    `
    background-image: linear-gradient(${CUSTOM_PALETTE.GRAY_100}, ${CUSTOM_PALETTE.GRAY_100}), 
    linear-gradient(90deg, #35CC76 0%, #1EA8E1 50%, #35CC76 100%);

    background-origin: border-box;
    background-clip: content-box, border-box;
    background-size: 200% 200%;
    animation: gradient 3s ease infinite;
  `}
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 150% 0%;
    }
    50% {
      background-position: 150% 150%;
    }
    75% {
      background-position: 0% 150%;
    }
    100% {
      background-position: 0% 0%;
    }
`;
const Write = styled.textarea`
  flex: 1;
  resize: none;
  ${(props) => props.theme.STYLES.INPUTBOX_TEXTINPUT}
`;

const SendButton = styled.button<{ disable: boolean }>`
  width: 32px;
  height: 32px;
  margin: 4px;
  padding: 4px 5px 4px 3px;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
  ${(props) =>
    `${props.disable
      ? `
        background-color: ${CUSTOM_PALETTE.GRAY_400};
        `
      : `
        background-color: ${CUSTOM_PALETTE.PRIMARY_DARK};
        &:focus{
          background-color: #57EE98;
        }
      `
    }`}
  
  
`;
const FakeCloseButton = styled.button`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 16px;
  right: 16px;tab
  background: none;
  border: none;
  border-radius:4px;
  &:focus{
    z-index:2;
    background: none;
  }
`;

const mapStateToProps = (state: any) => ({
  inputType: state.userInput.type,
  mode: state.system.mode,
  channelId: state.system.channelId,
  autoCompleteSelected: state.userInput.autoCompleteSelected,
  autoCompleteList: state.userInput.autoCompleteList,
  isApiUrlReset: state.server.isApiUrlReset,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      goBackHome: actions.goBackHome,
      sendMessage: actions.sendMessage,
      sendAutoCompleteQuery: actions.sendAutoCompleteQuery,
      setAutoCompleteSelected: actions.setAutoCompleteSelected,
      pushModal: actions.pushModal,
      startInput: actions.startInput,
      endInput: actions.endInput,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TextInput);
