import { env } from "client-page";

let API_URL =
  process.env.NODE_ENV === "development" ? env.REACT_APP_API_URL : null;
let API_PATH = env.REACT_APP_API_PATH;
let SITE_CODE = env.REACT_APP_SITE_CODE;
const MESSAGE_PATH = env.REACT_APP_MESSAGE_PATH;

export function resetApiUrl(params) {
  if (params) {
    const { apiUrl, apiPath, siteCode } = params;
    API_URL = apiUrl;
    API_PATH = apiPath;
    SITE_CODE = siteCode;
  } else {
    API_URL =
      process.env.NODE_ENV === "development" ? env.REACT_APP_API_URL : null;
    API_PATH = env.REACT_APP_API_PATH;
    SITE_CODE = env.REACT_APP_SITE_CODE;
  }
}

export function getHost() {
  if (API_URL) {
    let URL = API_URL;
    if (URL.endsWith("/")) {
      URL = URL.slice(0, URL.length - 1);
    }
    return `${URL}`;
  }
  return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
}

export function getAPIUrl() {
  return `${getHost()}${API_PATH}${SITE_CODE}`;
}

export function getMessageUrl() {
  return `${getHost()}${MESSAGE_PATH}`;
}
export function getWebSocketUrl(token) {
  return `${getHost()}?customer_session_id=${token}&site_code=${SITE_CODE}`;
}

export function makeMessageUri(url) {
  if (!url | (url === "")) return null;
  try {
    new URL(url);
    return url;
  } catch (e) {
    return getMessageUrl() + url;
  }
}
