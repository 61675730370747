import React, { useContext } from "react";
import { bindActionCreators } from "redux";
import styled, { ThemeContext, withTheme } from "styled-components";
import { connect } from "client-page";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import actions from "../../redux/actions";
const { stopWaitCounselor, waitCounselor } = actions;
function CounselEnterModal({
  stopWaitCounselor,
  waitCounselor,
  modalId,
  message,
  waiterCnt = 0,
  waitingTime,
  over,
}) {
  const theme = useContext(ThemeContext);
  const TITLE = theme.TEXTS;

  return (
    <Back>
      <OutsideClickHandler onOutsideClick={() => {}}>
        <Pane>
          <MainMessage>{"상담사 응답 대기중..."}</MainMessage>
        </Pane>
      </OutsideClickHandler>
    </Back>
  );
}

const Back = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  font-size: 18px;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;
const MainMessage = styled.div`
  font-size: 20px;
  padding: 20px;
  color: deepskyblue;
`;
const Text = styled.div`
  font-size: 15px;
  margin-left: 25px;
  margin-bottom: 10px;
  color: #606060;
`;
const Pane = styled.div`
  background-color: white;
  border-radius: 15px;
  margin: 20px;
  padding: 20px;
  margin-bottom: 10px;
  user-select: none;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;
const Button = styled.div`
  margin: 10px;
  display: flex;
  background-color: deepskyblue;
  border: 1px solid #e4e4e4;
  font-size: 15px;
  padding: 10px;
  border-radius: 5px;

  width: 130px;
  height: 20px;

  &:hover {
    background-color: skyblue;
  }
`;
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      waitCounselor: waitCounselor,
      stopWaitCounselor: stopWaitCounselor,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(CounselEnterModal));
