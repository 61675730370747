import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "mutationobserver-shim";
import "./index.css";
import myTheme from "./theme";
import { runChatbot } from "client-page";
import * as serviceWorker from "./serviceWorker";
import "./components";
import "./redux";
import actions from "./redux/actions";
import "./shared/modes";
import "./mvaccine/TouchEn_mVaccine";

const mapState = (state) => ({
  appModeType: state.system.mode.type,
  channelId: state.system.channelId,
});
const mapDispatch = {
  setTrigger: actions.setTrigger,
  startTrigger: actions.startTrigger,
  startConnection: actions.startConnection,
  clearMessages: actions.clearMessages,
  setChannelId: actions.setChannelId,
};

runChatbot({
  htmlId: "root", //배치될 html 엘리먼트의 아이디
  theme: myTheme, //테마
  mapExternalState: mapState, //chatbot_plugin용 getState 스테이트 맵핑 함수
  mapExternalDispatch: mapDispatch, //chatbot_plugin용 actionDispatch 함수 맵
});
serviceWorker.unregister();

//window.startTouchEn();
