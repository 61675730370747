import { utils, constants } from "client-page";

const { logger } = utils;

const TAG = "utils/cookie";

export function setCookie(cname, cvalue, exseconds) {
  logger.verbose(TAG, "setCookie()", cname, cvalue, exseconds);
  let d = new Date();
  d.setTime(d.getTime() + exseconds * 1000);
  let expires = "expires=" + d.toUTCString();
  //alert(cname + "=" + cvalue + ";" + expires + ";path=/")
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  //alert(document.cookie)
}

export function getCookie(cname) {
  logger.verbose(TAG, "getCookie()", cname);
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function deleteCookie(cname) {
  logger.verbose(TAG, "deleteCookie()", cname);
  //alert('deleteCookie setCookie')
  setCookie(cname, "", -1);
}
