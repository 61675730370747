import { utils } from "client-page";
import { getAPIUrl } from "../server/url";
/*
 * TouchEn mVaccine WEBJS
 * Copyright(C)2015 RaonSecure Co., Ltd.
 * Version 2.2.2
 * 2020-02-06
 */

String.prototype.contains = function (str) {
  return this.toLocaleLowerCase().indexOf(str) !== -1;
};

var version = "version=6.6";
var siteId = "idbins_chat";

var timeOutSession = 60;
var mvaccine_userAgent = window.navigator.userAgent.toLowerCase();

var mvaccine_popup_comment =
  "안전한 접속환경 유지를 위해 모바일 백신을<br>설치/실행 합니다.";
var mvaccine_popup_img = "mvaccine_popup_logo.png";
var app =
  "com.android.browser|org.mozilla.firefox|com.opera.browser|com.opera.browser.mini.classic|com.opera.browser.mini.android|com.android.chrome|com.sec.android.app.sbrowser|net.daum.android.daum|com.nhn.android.search|mobi.mgeek.tunnybrowser|com.ucmobile.intl";
var ff = mvaccine_userAgent.contains("firefox");
var old = mvaccine_userAgent.contains("android 2.");
var safari = mvaccine_userAgent.contains("safari");
var naver = mvaccine_userAgent.contains("naver");
var samsungBrowser7 = mvaccine_userAgent.contains("samsungbrowser/7");
var chrome = false;
if (mvaccine_userAgent.contains("chrome")) {
  chrome = true;
  safari = false;
}
// alert(mvaccine_userAgent);
var mVaccineLayer = "mVaccineLayer";

function TouchEn_mVaccine() {
  var top = window.top;
  var mVaccine_lic = window.mVaccine_lic;
  var callback_token = window.callback_token;
  var callback_url = window.callback_url;
  var validate_url = window.validate_url;
  this.mVaccine_lic = null;

  try {
    if (mVaccine_lic != null) this.mVaccine_lic = mVaccine_lic;
  } catch (e) {}
  this.callback_token = null;

  try {
    if (callback_token != null) this.callback_token = callback_token;
  } catch (e) {}

  this.callback_url = null;

  try {
    if (callback_url != null) {
      this.callback_url = callback_url;
    }
  } catch (e) {}

  this.validate_url = null;

  try {
    if (validate_url != null) {
      this.validate_url = validate_url;
    }
  } catch (e) {}

  this.useCallback = true;
  if (old) this.useCallback = false;
  this.mode = "mini";
  this.isReady = false;
  this.isStart = false;
  this.isRepeat = false;
  this.full = function () {
    if (this.callback_url.indexOf("http") == -1) {
      alert("callback_url의 프로토콜을 포함한 전체 주소를 입력해주세요.");
      return false;
    }
    if (this.mVaccine_lic == null) {
      alert("TouchEn mVaccine : 라이선스를 등록하세요.");
      return;
    }
    if (siteId == "") {
      alert("TouchEn mVaccine :  사이트아이디를 입력하세요.");
      return;
    }

    var i = "";
    i = "?siteid=" + siteId;
    i += "&licensekey=" + this.mVaccine_lic;
    i += "&" + version;
    i += "&update_pattern=" + true;
    i += "&update_engine=" + true;
    i += "&scan_rooting=" + true;
    i += "&scan_package=" + true;
    i += "&show_license=" + false;
    i += "&show_rooting=" + false;
    i += "&isFgOrBg=" + true;
    i += "&useBlackAppCheck=" + true;
    i += "&rootingexitapp=" + true;
    i += "&show_notify=" + true;
    i += "&rootingyesorno=" + false;
    i += "&rootingyes=" + false;
    i += "&showAbout=" + false;
    i += "&callback_url=" + this.callback_url;
    i += "&callback_token=" + this.callback_token;
    i += "&use_runningtime_scan=" + false;
    i += "&debug=" + false;
    i += "&result_enc=" + true;
    i += "&sessionid=JSESSIONID";

    if (old) {
      top.location.replace("smartvaccinestart://" + i);
      return;
    }

    if (safari) {
      if (
        document.getElementById("touchen_mvaccine_iframe").contentDocument ==
          null ||
        document.getElementById("touchen_mvaccine_iframe").contentDocument ==
          undefined
      ) {
        this.safariMarket();
        setTimeout(function () {
          document.body.removeChild(
            document.getElementById("touchen_mvaccine_iframe")
          );
          var iframe = document.createElement("iframe");
          iframe.setAttribute("id", "touchen_mvaccine_iframe");
          iframe.setAttribute("src", "smartvaccinecheck://");
          iframe.setAttribute(
            "style",
            "witdh:0px;height:0px;border:0px;visibility: hidden;"
          );
          document.body.appendChild(iframe);
        }, 1000);

        return;
      }
      var iframe = document.createElement("iframe");
      iframe.setAttribute("id", "touchen_mvaccine");
      iframe.setAttribute("src", "mvaccinestart://mvaccine" + i);
      iframe.setAttribute(
        "style",
        "witdh:0px;height:0px;border:0px;visibility: hidden;"
      );
      document.body.appendChild(iframe);
      setTimeout(function () {
        document.body.removeChild(document.getElementById("touchen_mvaccine"));
      }, 1000);
      setTimeout(function () {
        mVaccineCheckStatus(function (r) {
          if (!r) {
            _mVaccine.safariMarket();
          }
        });
      }, 3000);

      return;
    }

    if (ff) {
      top.location.href = "mvaccinestart://mvaccine" + i;
    } else {
      top.location.href =
        "intent://mvaccine" +
        i +
        "#Intent;scheme=mvaccinestart;package=com.TouchEn.mVaccine.webs;end";
    }
  };
  this.mini = function () {
    if (this.callback_url.indexOf("http") == -1) {
      alert("callback_url의 프로토콜을 포함한 전체 주소를 입력해주세요.");
      return false;
    }
    if (this.mVaccine_lic == null) {
      alert("TouchEn mVaccine : 라이선스를 등록하세요.");
      return;
    }
    if (siteId == "") {
      alert("TouchEn mVaccine :  사이트아이디를 입력하세요.");
      return;
    }

    var i = "";
    i = "?siteid=" + siteId;
    i += "&licensekey=" + this.mVaccine_lic;
    i += "&" + version;
    i += "&update_pattern=" + true;
    i += "&update_engine=" + true;
    i += "&scan_rooting=" + true;
    i += "&scan_package=" + true;
    i += "&target_type=" + false;
    i += "&isFgOrBg=" + false;
    i += "&useBlackAppCheck=" + true;
    i += "&rootingexitapp=" + true;
    i += "&rootingyesorno=" + false;
    i += "&timeOutSession=" + timeOutSession;
    i += "&showAbout=" + true;
    i += "&show_notify=" + true;
    i += "&debug=" + false;
    i += "&callback_cycle=" + 30;
    i += "&callback_url=" + this.callback_url;
    i += "&callback_token=" + this.callback_token;
    i += "&notifyClearable=" + false;
    i += "&notifyAutoClear=" + false;
    i += "&show_scan_ui=" + true;
    i += "&show_toast=" + false;
    i += "&use_runningtime_scan=" + false;
    i += "&result_enc=" + true;
    i += "&sessionid=JSESSIONID";

    if (old) {
      top.location.replace("smartbgvaccinestart://" + i);
      return;
    }

    if (safari) {
      if (
        document.getElementById("touchen_mvaccine_iframe").contentDocument ==
          null ||
        document.getElementById("touchen_mvaccine_iframe").contentDocument ==
          undefined
      ) {
        this.safariMarket();
        setTimeout(function () {
          document.body.removeChild(
            document.getElementById("touchen_mvaccine_iframe")
          );
          var iframe = document.createElement("iframe");
          iframe.setAttribute("id", "touchen_mvaccine_iframe");
          iframe.setAttribute("src", "smartvaccinecheck://");
          iframe.setAttribute(
            "style",
            "witdh:0px;height:0px;border:0px;visibility: hidden;"
          );
          document.body.appendChild(iframe);
        }, 1000);

        return;
      }
      var iframe = document.createElement("iframe");
      iframe.setAttribute("id", "touchen_mvaccine");
      iframe.setAttribute("src", "mvaccinestartbg://mvaccine" + i);
      iframe.setAttribute(
        "style",
        "witdh:0px;height:0px;border:0px;visibility: hidden;"
      );
      document.body.appendChild(iframe);
      setTimeout(function () {
        document.body.removeChild(document.getElementById("touchen_mvaccine"));
      }, 1000);
      setTimeout(function () {
        mVaccineCheckStatus(function (r) {
          if (!r) {
            _mVaccine.safariMarket();
          }
        });
      }, 3000);

      return;
    }

    if (ff) {
      top.location.href = "mvaccinestartbg://mvaccine" + i;
    } else {
      top.location.href =
        "intent://mvaccine" +
        i +
        "#Intent;scheme=mvaccinestartbg;package=com.TouchEn.mVaccine.webs;end";
    }
  };
  this.exit = function () {
    if (_mVaccine.isStart) return;

    _mVaccine.isStart = true;

    if (ff) {
      top.location.href = "mvaccineexit://mvaccine";
    } else if (old) {
      top.location.replace("smartvaccineexit://");
      return;
    } else if (safari) {
      var iframe = document.createElement("iframe");
      iframe.setAttribute("id", "touchen_mvaccine");
      iframe.setAttribute("src", "mvaccineexit://mvaccine");
      iframe.setAttribute(
        "style",
        "witdh:0px;height:0px;border:0px;visibility: hidden;"
      );
      document.body.appendChild(iframe);
      setTimeout(function () {
        document.body.removeChild(document.getElementById("touchen_mvaccine"));
      }, 1000);

      return;
    } else {
      top.location.href =
        "intent://mvaccine#Intent;scheme=mvaccineexit;package=com.TouchEn.mVaccine.webs;end";
    }

    setTimeout(() => {
      _mVaccine.isStart = false;
    }, 1000);
  };
  this.market = function () {
    top.location.href = "market://details?id=com.TouchEn.mVaccine.webs";
  };
  this.safariMarket = function () {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "touchen_mvaccine_safariMarket");
    iframe.setAttribute("src", "market://details?id=com.TouchEn.mVaccine.webs");
    iframe.setAttribute(
      "style",
      "witdh:0px;height:0px;border:0px;visibility: hidden;"
    );
    document.body.appendChild(iframe);
    setTimeout(function () {
      document.body.removeChild(
        document.getElementById("touchen_mvaccine_safariMarket")
      );
    }, 1000);
  };

  this.start = function () {
    if (_mVaccine.isStart) return;

    _mVaccine.isStart = true;

    if (this.mode == "mini") {
      this.mini();
    } else {
      this.full();
    }

    setTimeout(() => {
      _mVaccine.isStart = false;
    }, 1000);
  };
}

function mVaccine_check() {
  if (_mVaccine.isReady) return false;

  mVaccineCheckStatus(function (result) {
    if (!result) mVaccineOpenLayerDialog();
  });

  return false;
}

function mVaccine_onload() {
  if (!samsungBrowser7) {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "touchen_mvaccine_iframe");
    iframe.setAttribute("src", "smartvaccinecheck://");
    iframe.setAttribute(
      "style",
      "witdh:0px;height:0px;border:0px;visibility: hidden;"
    );
    document.body.appendChild(iframe);
  }

  _mVaccine.mode = "mini";

  mVaccineGetToken();

  mVaccine_check();
}
function mVaccine_reload() {
  mVaccineSessionRemove(function () {
    if (!mVaccine_check()) return;

    _mVaccine.start();
  });
}

function mVaccineCheck() {
  if (_mVaccine.isStart) return;

  _mVaccine.isStart = true;

  if (safari) {
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "touchen_mvaccine");
    iframe.setAttribute("src", "market://details?id=com.TouchEn.mVaccine.webs");
    iframe.setAttribute(
      "style",
      "witdh:0px;height:0px;border:0px;visibility: hidden;"
    );
    document.body.appendChild(iframe);
    setTimeout(function () {
      document.body.removeChild(document.getElementById("touchen_mvaccine"));
    }, 1000);

    return;
  } else {
    _mVaccine.market();
  }

  setTimeout(() => {
    _mVaccine.isStart = false;
  }, 1000);
}
var mVaccineScanCheckTimer = null;
function mVaccineStart() {
  _mVaccine.start();
  mVaccineScanCheckTimer = setInterval(() => {
    mVaccineScanCheck(true);
  }, 2000);
}

function mVaccineScanCheck(repeat) {
  _mVaccine.isRpeat = repeat;
  if (!_mVaccine.useCallback) {
    mVaccineLayerClose();
    return;
  }

  mVaccineCheckStatus(mVaccineScanCheckCallback);
}

function mVaccineScanCheckCallback(result) {
  if (result) {
    mVaccineLayerClose();
    clearInterval(mVaccineScanCheckTimer);
  } else {
    if (!_mVaccine.isRpeat) clearInterval(mVaccineScanCheckTimer);
  }
}

function mVaccineCheckStatus(callbackFunction) {
  var request = new XMLHttpRequest();
  request.open("POST", _mVaccine.callback_url, true);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      mVaccineCheckStatusCkResult(returnValue, callbackFunction);
    }
  };
  request.send();

  return false;
}

function mVaccineGetToken() {
  var request = new XMLHttpRequest();
  request.open("POST", _mVaccine.callback_url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      mVaccineGetTokenCkResult(returnValue);
    }
  };
  request.send();
}

function mVaccineGetTokenCkResult(resultValue) {
  var request = new XMLHttpRequest();
  var params = "?value=" + resultValue;
  var url = _mVaccine.validate_url + params;
  url = url.replace(/&/g, "%26");
  url = url.replace(/\+/g, "%2B");

  request.open("POST", url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      var returnValueArray = returnValue.split(",");
      var cToken = returnValueArray[4];
      _mVaccine.callback_token = cToken;
    }
  };
  request.send();

  return false;
}

function mVaccineCheckStatusCkResult(resultValue, callbackFunction) {
  var request = new XMLHttpRequest();
  var params = "?value=" + resultValue;
  var url = _mVaccine.validate_url + params;
  url = url.replace(/&/g, "%26");
  url = url.replace(/\+/g, "%2B");

  request.open("POST", url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      var returnValueArray = returnValue.split(",");
      returnValue = returnValueArray[0];

      if (returnValue == "null") {
        var cToken = returnValueArray[4];
        if (cToken != null) {
          if (_mVaccine.callback_token != cToken)
            _mVaccine.callback_token = cToken;
        }
      }

      if (callbackFunction != null) {
        if (returnValue == "null") callbackFunction(false);
        else callbackFunction(true);
      } else {
        if (returnValue == "null") return false;
        else return true;
      }
    }
  };
  request.send();

  return false;
}

function mVaccineSessionRemove(callbackFunction) {
  var request = new XMLHttpRequest();
  request.open(
    "POST",
    _mVaccine.callback_url + "?mVaccine_op=setCheck&mVaccine_check=false",
    false
  );
  if (callbackFunction != null) request.onreadystatechange = callbackFunction;
  request.send();
}

function mVaccineOpenLayerDialog() {
  if (document.getElementById(mVaccineLayer) != null) return;

  var div1 = document.createElement("DIV");
  div1.id = mVaccineLayer;
  div1.style.position = "absolute";
  div1.style.height = "100%";
  div1.style.width = "100%";
  div1.style.top = "0";
  div1.style.left = "0";
  div1.style.background = "#000000";
  div1.style.opacity = "0.4";
  div1.style.filter = "alpha(opacity=40)";

  var div2 = document.createElement("DIV");
  div2.style.cssText =
    "color:grey;width: 200px;z-index: 1001;border: 3px solid white;border-radius: 11px;background: white;font-family:monospace";
  div2.innerHTML = "";
  if (mvaccine_popup_img.length > 0)
    div2.innerHTML +=
      "<div style='margin: 5px;margin-top: 10px;width: 190px;height: 24px;background-repeat: no-repeat;background-size: 100%;background-image: url(" +
      mvaccine_popup_img +
      ");'></div>";
  div2.innerHTML +=
    "<div style='margin: 5px;margin-top: 15px;'><span id='mVaccineLayerText1'>" +
    mvaccine_popup_comment +
    "</span></div>";
  div2.innerHTML += "<div style='margin: 5px;'>";

  if (!chrome)
    div2.innerHTML +=
      "<span id='mVaccineCheck'><button style='background: #9d9d9d;border-radius: 15px;margin: 10px;height:30px;width: 70px;border: 3px solid #9d9d9d;font-size: medium;font-family: monospace;' onclick='mVaccineCheck();'>설치</button></span>";

  div2.innerHTML +=
    "<span id='mVaccineStart''><button onclick='mVaccineStart();' style='font-size: medium;background: #9d9d9d;border-radius: 15px;margin: 10px;height:30px;width: 80px;border: 3px solid #9d9d9d;font-family: monospace;'>실행</button></span>";
  div2.innerHTML += "</div>";

  var div3 = document.createElement("DIV");
  div3.id = mVaccineLayer + "_popup";
  div3.style.cssText =
    "position: absolute;  display: table-cell;text-align: center; vertical-align: middle; color: white;z-index: 1000;";
  div3.appendChild(div2);

  document.body.appendChild(div1);
  document.body.appendChild(div3);

  mVaccineLayerResize();

  window.onresize = function (event) {
    mVaccineLayerResize();
  };

  _mVaccine.isReady = true;
}

function mVaccineLayerResize() {
  var div1 = document.getElementById(mVaccineLayer);
  var div2 = document.getElementById(mVaccineLayer + "_popup");
  if (div2) {
    div2.style.top = div1.clientHeight / 2 - 100 + "px";
    div2.style.left = div1.clientWidth / 2 - 100 + "px";
  }
}

function mVaccineLayerClose() {
  var close = true;
  if (close) {
    _mVaccine.isReady = false;
    var div1 = document.getElementById(mVaccineLayer);
    var div2 = document.getElementById(mVaccineLayer + "_popup");

    if (div1) {
      document.body.removeChild(div1);
    }
    if (div2) {
      document.body.removeChild(div2);
    }

    window.onresize = null;
  }
}
var _mVaccine = new TouchEn_mVaccine();
/////////////////////mVaccine setting value///////////////////////////////////
_mVaccine.mVaccine_lic = "509d21bb3c9a68866a7d99706ce09ccaea02317e";
_mVaccine.callback_url = `${getAPIUrl()}/vaccine/callback`;
_mVaccine.validate_url = `${getAPIUrl()}/vaccine/validate`;
_mVaccine.callback_token_url = `${getAPIUrl()}/vaccine/token`;
_mVaccine.callback_token = "";
/////////////////////mVaccine setting value///////////////////////////////////

function mVaccineCallbackCheckDemo(callbackFunction) {
  var request = new XMLHttpRequest();
  request.open("POST", _mVaccine.callback_url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      mVaccineCheckResultDemo(returnValue, callbackFunction);
    }
  };
  request.send();

  setTimeout(() => {
    mVaccineCallbackCheckDemo(callbackFunctionDemo);
  }, 10000);

  return false;
}

function mVaccineCheckResultDemo(resultValue, callbackFunction) {
  var request = new XMLHttpRequest();
  var params = "?value=" + resultValue;
  var url = _mVaccine.validate_url + params;
  url = url.replace(/&/g, "%26");
  url = url.replace(/\+/g, "%2B");

  request.open("POST", url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      var returnValueArray = returnValue.split(",");
      returnValue = returnValueArray[0];

      if (returnValue == "null") {
        var cToken = returnValueArray[4];
        if (cToken != null) {
          if (_mVaccine.callback_token != cToken)
            _mVaccine.callback_token = cToken;
        }
      }

      if (callbackFunction != null) {
        callbackFunction(returnValueArray);
      }
    }
  };
  request.send();

  return false;
}

function callbackFunctionDemo(returnValueArray) {
  var mVaccineTime = parseInt(returnValueArray[1]);
  var rooting_detected = returnValueArray[2];
  var virus_detected = returnValueArray[3];

  var limitTime = 31; // callback_check.jsp 의 resultString null 설정 시간과 동일하게 맞춰줌

  if (mVaccineTime > limitTime) {
    mVaccine_onload(); // 31초 동안 백신이 서버에 접속하지 않으면 백신 다시 시작
  }
  //alert("rooting_detected:"+rooting_detected+", virus_detected:"+virus_detected);

  if (rooting_detected != "0") {
    alert("루팅된 단말입니다.");
    mVaccineSessionRemove();
    mVaccine_onload();
    return false; // root or virus
  }
  if (virus_detected != "0") {
    alert("악성코드가 발견되었습니다.치료하고 재접속바랍니다.");
    mVaccineSessionRemove();
    mVaccine_onload();
    return false; // root or virus
  }
}

function startmVaccine() {
  if (!utils.isAndroid()) return;
  var request = new XMLHttpRequest();
  request.open("POST", _mVaccine.callback_token_url, false);
  request.onreadystatechange = function () {
    if (request.readyState == 4 && request.status == 200) {
      var returnValue = JSON.parse(request.responseText).data.result.replace(
        /^\s+|\s+$/g,
        ""
      );
      _mVaccine.callback_token = returnValue;
      mVaccine_onload();
      setTimeout(() => {
        mVaccineCallbackCheckDemo(callbackFunctionDemo);
      }, 5000);
    }
  };
  request.send();
}

export function startTouchEn() {
  if (utils.isMobile()) {
    window.addEventListener("load", function () {
      startmVaccine();
    });
  } else {
    document.write(`
		<script
		  type="text/javascript"
		  src="/raonnx/jquery/jquery-1.6.3.js"
		></script>
		<script type="text/javascript" src="/raonnx/cmn/TouchEnNx.js"></script>`);
  }
}

window.mVaccineStart = mVaccineStart;
window.mVaccineCheck = mVaccineCheck;
window.startTouchEn = startTouchEn;
