import { createAction } from "redux-actions";
import { RegisterReducerDef } from "client-page";
import { call, put } from "redux-saga/effects";

export const clientTest = createAction("CLIENT_TEST", (arg1, arg2) => ({
  arg1,
  arg2,
}));
export const clientTestSaga = createAction("CLIENT_SAGA");
export const clientTestSaga2 = createAction("CLIENT_SAGA2");

const initState = {
  tt: "test test on testclient",
};

const reducerDef = {
  path: "clientReducer",
  initState,

  reducerMap: {
    CLIENT_TEST: (state, action) => {
      console.log("client test", action.payload);
      return Object.assign({}, state, {});
    },
    CLIENT_TEST2: (state, action) => {
      console.log("client test 2");
      return Object.assign({}, state, {});
    },
  },
  sagaMap: {
    CLIENT_SAGA: function* (action) {
      function sleep(delay) {
        return new Promise((resolve) => setTimeout(resolve, delay));
      }
      async function awaaait() {
        for (let i = 0; i < 10; i++) {
          await sleep(1000);
          console.log("timetimetime");
        }
      }
      yield call(awaaait);
      yield put({ type: "CLIENT_TEST2", payload: 2 });
      yield put({ type: "CLIENT_TEST2", payload: 5 });
    },
    CLIENT_SAGA2: function* (action) {
      // debugger;
      // yield put(clientTestSaga());
      // const act = yield take(
      //   (action) => action.type === "CLIENT_TEST2" && action.payload === 5
      // );
      // debugger;
      // debugger;
      // yield call(reducerDef.sagaMap.CLIENT_SAGA, clientTestSaga());
      // debugger;
    },
  },
};

RegisterReducerDef(reducerDef);
